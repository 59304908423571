import React, { useEffect } from 'react'
import styles from '../../css/Card.module.css'
import PropTypes from 'prop-types'
import { Card, CardContent, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import RefreshIcon from '@mui/icons-material/Refresh'
import LinkIcon from '@mui/icons-material/Link'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { Auth } from 'aws-amplify'
import HighlightDecorator from '../highlight/HighlightDecorator'
import Delimeter from '../common/Delimeter'
import LinkPopover from '../common/LinkPopover'
import TagOptions from '../common/TagOptions'
import Stats from '../../stats/Stats'

HighlightCard.propTypes = {
  value: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRefresh: PropTypes.func,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  isNext: PropTypes.bool,
  isPrev: PropTypes.bool
}

export default function HighlightCard (props) {
  const {
    value,
    onEdit,
    onDelete,
    onRefresh,
    onPrev,
    onNext,
    isNext,
    isPrev
  } = props

  const [popoverEl, setPopoverEl] = React.useState(null)
  const [canDelete, setCanDelete] = React.useState(false)
  let stats
  try {
    stats = Stats(value.stats ? value.stats.items : null)
  } catch (err) {
    console.log(err)
  }

  useEffect(() => {
    const getUserGroups = async () => {
      const user = await Auth.currentAuthenticatedUser()
      setCanDelete(user.username === value.owner)
    }
    getUserGroups()
  }, [])

  const editClicked = ev => {
    if (onEdit) {
      onEdit(ev, value)
    }
  }

  const deleteClicked = ev => {
    if (onDelete) {
      onDelete(ev, value)
    }
  }

  const refreshClicked = ev => {
    if (onRefresh) {
      onRefresh(ev, value)
    }
  }

  const linkClicked = ev => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_REDIRECT_URL}/upload/${value.uploadHighlightsId}/highlight/${value.id}`)
    setPopoverEl(ev.target)
    setTimeout(() => {
      setPopoverEl(null)
    }, 2000)
    return null
  }

  const prevClicked = () => {
    if (onPrev) {
      onPrev()
    }
  }

  const nextClicked = () => {
    if (onNext) {
      onNext()
    }
  }

  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography className={styles.cardTitle} gutterBottom>
          <HighlightDecorator value={value}/> {value.name}<br/>
          {
            !isPrev
              ? <IconButton color="primary" size="small" onClick={prevClicked} disabled={true}>
                <NavigateBeforeIcon fontSize="small"/>
              </IconButton>
              : <Tooltip title="Go to the previous highlight.">
                <IconButton color="primary" size="small" onClick={prevClicked} disabled={false}>
                  <NavigateBeforeIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
          }
          {
            !isNext
              ? <IconButton color="primary" size="small" onClick={nextClicked} disabled={true}>
                <NavigateNextIcon fontSize="small"/>
              </IconButton>
              : <Tooltip title="Go to the next highlight.">
                <IconButton color="primary" size="small" onClick={nextClicked} disabled={false}>
                  <NavigateNextIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
          }
          <Tooltip title="Edit the current highlight.">
            <IconButton color="primary" size="small" onClick={editClicked}>
              <EditIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Refresh/replay the current video.">
            <IconButton color="primary" size="small" onClick={refreshClicked}>
              <RefreshIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Get a link to the current highlight.">
            <IconButton color="primary" size="small" onClick={linkClicked}>
              <LinkIcon fontSize="small"/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete the current highlight.">
            <span>
            <IconButton color="secondary" size="small" onClick={deleteClicked} disabled={!canDelete}>
              <DeleteIcon fontSize="small"/>
            </IconButton>
            </span>
          </Tooltip>
          <LinkPopover el={popoverEl} setEl={setPopoverEl} horizontal="left">
            <Typography
              sx={{ p: 2 }}
              className={styles.cardTitle}
              color="success.main">
              <CheckCircleIcon
                fontSize="small"
                sx={{ verticalAlign: 'middle' }}
              /> Highlight link copied.
            </Typography>
          </LinkPopover>
        </Typography>
        {
          value.description
            ? <Typography variant="body2" color="text.secondary">
              {
                value.description.split('\n').map((token, index) => {
                  return (
                    <span key={index}>
                      {token}<br/>
                    </span>
                  )
                })
              }
            </Typography>
            : null
        }
        {
          value.owner
            ? <Typography variant="body2" color="text.secondary">Author: {value.owner}</Typography>
            : null
        }
        {
          value.groups
            ? <Typography variant="body2" color="text.secondary">
              Groups: <Delimeter values={value.groups}/>
            </Typography>
            : null
        }
        <Typography variant="body2" color="text.secondary">
          Tags:&nbsp;
          <TagOptions values={value.tags.items} urlFn={item => `/tag/${item.name}`}/>
        </Typography>

        <Typography variant="body2" color="text.secondary">
          Stats:&nbsp;
          <TagOptions values={value.stats ? value.stats.items : null}/>
        </Typography>

        <Typography variant="body2" color="text.secondary">

        </Typography>
      </CardContent>
    </Card>
  )
}
