/* eslint-disable */

export const createUpload = /* GraphQL */ `
  mutation CreateUpload(
    $input: CreateUploadInput!
    $condition: ModelUploadConditionInput
  ) {
    createUpload(input: $input, condition: $condition) {
      id
      name
      description
      date
      url
      source
      highlights {
        nextToken
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUpload = /* GraphQL */ `
  mutation UpdateUpload(
    $input: UpdateUploadInput!
    $condition: ModelUploadConditionInput
  ) {
    updateUpload(input: $input, condition: $condition) {
      id
      name
      description
      date
      url
      source
      highlights {
        nextToken
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUpload = /* GraphQL */ `
  mutation DeleteUpload(
    $input: DeleteUploadInput!
    $condition: ModelUploadConditionInput
  ) {
    deleteUpload(input: $input, condition: $condition) {
      id
      name
      description
      date
      url
      source
      highlights {
        nextToken
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createHighlight = /* GraphQL */ `
  mutation CreateHighlight(
    $input: CreateHighlightInput!
    $condition: ModelHighlightConditionInput
  ) {
    createHighlight(input: $input, condition: $condition) {
      id
      month
      name
      description
      start
      end
      upload {
        id
        name
        description
        date
        url
        source
        owner
        groups
        createdAt
        updatedAt
        __typename
      }
      tags {
        nextToken
        __typename
      }
      stats {
        nextToken
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      uploadHighlightsId
      __typename
    }
  }
`;
export const updateHighlight = /* GraphQL */ `
  mutation UpdateHighlight(
    $input: UpdateHighlightInput!
    $condition: ModelHighlightConditionInput
  ) {
    updateHighlight(input: $input, condition: $condition) {
      id
      month
      name
      description
      start
      end
      upload {
        id
        name
        description
        date
        url
        source
        owner
        groups
        createdAt
        updatedAt
        __typename
      }
      tags {
        nextToken
        __typename
      }
      stats {
        nextToken
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      uploadHighlightsId
      __typename
    }
  }
`;
export const deleteHighlight = /* GraphQL */ `
  mutation DeleteHighlight(
    $input: DeleteHighlightInput!
    $condition: ModelHighlightConditionInput
  ) {
    deleteHighlight(input: $input, condition: $condition) {
      id
      month
      name
      description
      start
      end
      upload {
        id
        name
        description
        date
        url
        source
        owner
        groups
        createdAt
        updatedAt
        __typename
      }
      tags {
        nextToken
        __typename
      }
      stats {
        nextToken
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      uploadHighlightsId
      __typename
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      month
      date
      highlight {
        id
        month
        name
        description
        start
        end
        owner
        groups
        createdAt
        updatedAt
        uploadHighlightsId
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      highlightTagsId
      __typename
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      month
      date
      highlight {
        id
        month
        name
        description
        start
        end
        owner
        groups
        createdAt
        updatedAt
        uploadHighlightsId
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      highlightTagsId
      __typename
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      month
      date
      highlight {
        id
        month
        name
        description
        start
        end
        owner
        groups
        createdAt
        updatedAt
        uploadHighlightsId
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      highlightTagsId
      __typename
    }
  }
`;
export const createStats = /* GraphQL */ `
  mutation CreateStats(
    $input: CreateStatsInput!
    $condition: ModelStatsConditionInput
  ) {
    createStats(input: $input, condition: $condition) {
      id
      name
      month
      date
      highlight {
        id
        month
        name
        description
        start
        end
        owner
        groups
        createdAt
        updatedAt
        uploadHighlightsId
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      highlightStatsId
      __typename
    }
  }
`;
export const updateStats = /* GraphQL */ `
  mutation UpdateStats(
    $input: UpdateStatsInput!
    $condition: ModelStatsConditionInput
  ) {
    updateStats(input: $input, condition: $condition) {
      id
      name
      month
      date
      highlight {
        id
        month
        name
        description
        start
        end
        owner
        groups
        createdAt
        updatedAt
        uploadHighlightsId
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      highlightStatsId
      __typename
    }
  }
`;
export const deleteStats = /* GraphQL */ `
  mutation DeleteStats(
    $input: DeleteStatsInput!
    $condition: ModelStatsConditionInput
  ) {
    deleteStats(input: $input, condition: $condition) {
      id
      name
      month
      date
      highlight {
        id
        month
        name
        description
        start
        end
        owner
        groups
        createdAt
        updatedAt
        uploadHighlightsId
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      highlightStatsId
      __typename
    }
  }
`;
