import { Amplify, Auth } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import awsconfig from '../src/aws-exports'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { ConfirmProvider } from 'material-ui-confirm'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
})

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

export default function Index () {
  return (
    <Authenticator.Provider>
      <Authenticator>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <App/>
        </LocalizationProvider>
      </Authenticator>
    </Authenticator.Provider>
  )
}

const container = document.getElementById('app')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline enableColorScheme/>
      <StyledEngineProvider injectFirst>
        <ConfirmProvider>
          <Index/>
        </ConfirmProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
