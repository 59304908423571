import * as React from 'react'
import { useEffect, useState } from 'react'
import { listHighlights, listUploads } from '../service'
import { Grid, Stack, Typography } from '@mui/material'
import UploadPreviewCard from '../components/card/UploadPreviewCard'

const tagData = highlights => {
  const results = []
  if (highlights) {
    for (const h of highlights) {
      results.push({
        value: h.name,
        count: Math.random() * 100 // Temporary count, should be the number of times this tag has been used.
      })
    }
  }
  return results
}

export default function Uploads () {
  const [uploads, setUploads] = useState([])
  const [tags, setTags] = useState({})

  useEffect(() => {
    const loadUploads = async () => {
      try {
        const result = await listUploads()
        setUploads(result)
      } catch (err) {
        console.error(err)
        window.alert('Error while retrieving uploads.')
      }
    }
    loadUploads()
  }, [])

  useEffect(() => {
    const loadHighlights = async () => {
      let id, name
      if (uploads) {
        try {
          for (const u of uploads) {
            id = u.id
            name = u.name
            const result = await listHighlights(u.id)
            tags[u.id] = tagData(result)
            setTags(tags)
          }
        } catch (err) {
          console.error(`Error while loading highlights for upload (${id}, ${name}) - ${JSON.stringify(err, null, 2)}`)
          console.error(err)
          if (err.response) {
            window.alert(JSON.stringify(err.response, null, 2))
          }
          // window.alert(`Error while loading highlights for upload (${id}, ${name}) - ${JSON.stringify(err, null, 2)}`)
        }
      }
    }
    loadHighlights()
  }, [uploads])

  return (
    <Stack sx={{ marginLeft: '.5em' }} spacing={0} rowGap={2}>
      <Typography variant="h5">
        Your Upload Stream
      </Typography>
      {
        uploads.map(upload => (
          <Grid container key={upload.id} spacing={3}>
            <Grid item xs={12} md={8} lg={6}>
              <UploadPreviewCard upload={upload}/>
            </Grid>
          </Grid>
        ))
      }
    </Stack>
  )
}
