import * as React from 'react'
import PropTypes from 'prop-types'
import { Alert, Snackbar } from '@mui/material'

Messages.propTypes = {
  message: PropTypes.object,
  onClose: PropTypes.func
}

export default function Messages (props) {
  const { message, onClose } = props

  let timeout = 5000
  if (message) {
    if (message.timeout <= 0) {
      timeout = null
    } else if (message.severity === 'error') {
      timeout = null
    }
  }
  return (
    <>
      {
        message
          ? <Snackbar
            open={true}
            autoHideDuration={timeout}
            onClose={onClose}
          >
            <Alert
              severity={message.severity ? message.severity : 'info'}
              sx={{ width: '100%' }}>
              {message.text}
            </Alert>
          </Snackbar>
          : null
      }
    </>
  )
}
