import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  createHighlight,
  deleteHighlight,
  getHighlight,
  getUpload,
  listUploads,
  updateHighlight,
  updateUpload
} from '../service'
import VideoJS from '../components/video/VideoJS'
import 'videojs-overlay'
import 'videojs-overlay/dist/videojs-overlay.css'
import HighlightDialog from '../components/highlight/HighlightDialog'
import HighlightCard from '../components/card/HighlightCard'
import PlayerStatusCard from '../components/card/PlayerStatusCard'
import Box from '@mui/material/Box'
import { Alert, Autocomplete, Grid, Skeleton, TextField } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import HighlightDecorator from '../components/highlight/HighlightDecorator'
import UploadDialog from '../components/upload/UploadDialog'
import ControlsDialog from '../components/video/ControlsDialog'
import UploadCard from '../components/card/UploadCard'
import Messages from '../components/common/Messages'
import Comparator from '../components/common/Comparator'

const Upload = () => {
  // eslint-disable-next-line no-unused-vars
  const params = useParams()
  const navigate = useNavigate()
  const [message, setMessage] = useState(null)
  const [uploadId, setUploadId] = useState(params.uploadId)
  const [highlightId, setHighlightId] = useState(params.highlightId)
  const [uploads, setUploads] = useState(null)
  const [upload, setUpload] = useState(null)
  const [highlights, setHighlights] = useState([])
  const [highlight, setHighlight] = useState(null)
  const [currentTime, setCurrentTime] = useState(null)
  const [currentRate, setCurrentRate] = useState(1)
  const [currentRemaining, setCurrentRemaining] = useState(null)
  const [currentQuality, setCurrentQuality] = useState(null)
  const [newHighlight, setNewHighlight] = useState(null)
  const [editingUpload, setEditingUpload] = useState(false)
  const [editingHighlight, setEditingHighlight] = useState(false)
  const [showHelp, setShowHelp] = useState(false)
  const [eventsPaused, setEventsPaused] = useState(false)
  const markRef = useRef(null)
  const playerRef = useRef(null)
  const confirm = useConfirm()

  /**
   * Load the upload id from the router.
   */
  useEffect(() => {
    if (params.uploadId !== uploadId) {
      setUploadId(params.uploadId)
    }
    if (params.highlightId !== highlightId) {
      setHighlightId(params.highlightId)
    }
  }, [params])

  /**
   * Load the uploads from the server.
   */
  useEffect(() => {
    const init = async () => {
      const data = await listUploads()
      setUploads(data)
    }
    init()
  }, [])

  /**
   * Set the current upload.
   */
  useEffect(() => {
    if (uploadId) {
      const loadUpload = async () => {
        try {
          const u = await getUpload(uploadId)
          document.title = `Highlights - ${u.name}`
          setUpload(u)
          const sortedHighlights = u.highlights.items.sort(Comparator.NumberComparator('start'))
          setHighlights(sortedHighlights)
        } catch (err) {
          console.error(err)
          setMessage({
            severity: 'error',
            text: 'Error retrieving upload.'
          })
        }
      }
      loadUpload()
    } else {
      setUpload(null)
    }
  }, [uploadId])

  /**
   * Load the current highlight.
   */
  useEffect(() => {
    if (highlightId) {
      const loadHighlight = async () => {
        try {
          const h = await getHighlight(highlightId)
          if (upload && upload.highlights && !upload.highlights.items.find(item => item.id === h.id)) {
            upload.highlights.items.push(h)
            upload.highlights.items = upload.highlights.items.sort(Comparator.NumberComparator('start'))
          }
          setHighlight(h)
        } catch (err) {
          console.error(err)
          setMessage({
            severity: 'error',
            text: 'Error retrieving highlight'
          })
        }
      }
      loadHighlight()
    } else {
      setHighlight(null)
    }
  }, [highlightId])

  useEffect(() => {
    if (highlight) {
      if (playerRef.current) {
        if (playerRef.current.currentTime() !== highlight.start) {
          playerRef.current.currentTime(highlight.start)
        }
      } else {
        setTimeout(() => {
          if (playerRef.current.currentTime() !== highlight.start) {
            playerRef.current.currentTime(highlight.start)
          }
        }, 500)
      }
    }
  }, [highlight])

  const pushUrlState = (newUploadId, newHighlightId) => {
    const base = '/upload'
    if (!newUploadId) {
      navigate(base)
    } else {
      const baseWithUpload = `${base}/${newUploadId}`
      if (newHighlightId) {
        navigate(`${baseWithUpload}/highlight/${newHighlightId}`)
      } else {
        navigate(baseWithUpload)
      }
    }
  }

  const uploadSelected = (ev, newValue) => {
    pushUrlState(newValue ? newValue.id : null)
  }

  const highlightSelected = (ev, newValue) => {
    pushUrlState(uploadId, newValue ? newValue.id : null)
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player
  }

  const formatTime = time => {
    const multiplier = Math.pow(10, 1 || 0)
    return Math.round(time * multiplier) / multiplier
  }

  const timeUpdated = ct => {
    setCurrentTime(formatTime(ct))
    setCurrentRemaining(formatTime(playerRef.current.duration() - ct))
  }

  const rateUpdated = rate => {
    setCurrentRate(formatTime(rate))
  }

  const qualityChanged = quality => {
    setCurrentQuality(quality)
  }

  const markSet = async time => {
    if (markRef.current) {
      // Completed a mark
      const start = markRef.current
      const end = formatTime(time)
      if (start === end) {
        setMessage({
          severity: 'error',
          text: 'Invalid mark, the start is the same as the end.'
        })
        return // **EXIT**
      } else if (end < start) {
        setMessage({
          severity: 'error',
          text: 'Invalid mark, the end is before the start.'
        })
      }

      setEventsPaused(true)

      if (playerRef.current.isFullscreen()) {
        playerRef.current.exitFullscreen()
      }
      playerRef.current.pause()
      setNewHighlight({
        start,
        end
      })
    } else {
      markRef.current = formatTime(time)
    }
  }

  const onEditUpload = () => {
    setEventsPaused(true)
    if (playerRef.current) {
      playerRef.current.pause()
    }
    setEditingUpload(true)
  }
  const onSaveUpload = async (ev, updated) => {
    try {
      const result = await updateUpload(updated)
      setMessage({
        severity: 'success',
        text: `Successfully Updated Upload: ${updated.name}`
      })
      setUpload(result)
      for (let i = 0; i < uploads.length; i++) {
        if (uploads[i].id === result.id) {
          uploads[i] = result
          break
        }
      }
      setEditingUpload(false)
    } catch (err) {
      console.error(err)
      setMessage({
        severity: 'error',
        text: `Error while updating Upload: ${updated.name}`
      })
    } finally {
      setEventsPaused(false)
    }
  }

  const onCancelSaveUpload = () => {
    setEditingUpload(false)
  }

  const onCreateHighlight = async (ev, data) => {
    try {
      if (!data.name) {
        setMessage({
          severity: 'error',
          text: 'Highlight name is a required field.'
        })
      }
      const created = await createHighlight(Object.assign(
        {},
        data,
        {
          uploadHighlightsId: upload.id
        }
      ))
      console.log(`Created new highlight with id: ${created.id}`)
      markRef.current = null
      setNewHighlight(null)
      setEventsPaused(false)
      pushUrlState(uploadId, created.id)

      setMessage({
        severity: 'success',
        text: `Successfully created highlight: ${created.name}`
      })
    } catch (err) {
      console.error(err)
      setMessage({
        severity: 'error',
        text: 'Error while creating highlight.'
      })
    }
  }

  const onCancelCreateHighlight = () => {
    setEventsPaused(false)
    setNewHighlight(null)
  }

  const onEditHighlight = () => {
    if (highlight) {
      setEventsPaused(true)
      playerRef.current.pause()
      setEditingHighlight(true)
    }
  }

  const onDeleteHighlight = async () => {
    await confirm({ description: `Are you sure you want to delete highlight: ${highlight.name}` })
    try {
      // Find the index of the deleted highlight.
      const index = highlights.findIndex(item => item.id === highlight.id)
      // Determine the id of the new current highlight.
      let newHighlightId = null
      if (index === 0 && highlights.length > 1) {
        newHighlightId = highlights[1].id
      } else if (highlights.length > 1) {
        newHighlightId = highlights[index - 1].id
      }
      await deleteHighlight(highlightId)
      // Remove the deleted highlight from the list.
      highlights.splice(index, 1)
      setHighlightId(null)
      pushUrlState(uploadId, newHighlightId)
    } catch (err) {
      console.log(err)
      setMessage({
        severity: 'error',
        text: 'Error deleting highlight.'
      })
    }
  }

  const onRefreshHighlight = () => {
    playerRef.current.currentTime(highlight.start)
  }

  const onPrev = () => {
    const index = highlights.findIndex(item => item.id === highlight.id)
    if (index - 1 >= 0) {
      pushUrlState(uploadId, highlights[index - 1].id)
    }
  }

  const onNext = () => {
    const index = highlights.findIndex(item => item.id === highlight.id)
    if (index + 1 < highlights.length) {
      pushUrlState(uploadId, highlights[index + 1].id)
    }
  }

  const onSaveHighlight = async (ev, data) => {
    try {
      const updated = await updateHighlight(data)
      console.log(`Updated highlight with id: ${updated.id}`)
      setHighlight(await getHighlight(data.id))
      setEventsPaused(false)
      setEditingHighlight(false)

      setMessage({
        severity: 'success',
        text: `Successfully saved highlight: ${updated.name}`
      })
    } catch (err) {
      console.error(err)
      console.dir(data)
      setMessage({
        severity: 'error',
        text: `Unable to save higlight: ${data.name}`
      })
    }
  }

  const onCancelSaveHighlight = () => {
    setEventsPaused(false)
    setEditingHighlight(false)
  }

  const cleared = () => {
    if (markRef.current) {
      markRef.current = null
    }
  }

  const onShowHelp = () => {
    setEventsPaused(true)
    setShowHelp(true)
  }

  const onHelpClose = () => {
    setEventsPaused(false)
    setShowHelp(false)
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8} lg={9}>
          {/* Main area */}
          <Grid item xs={12} container sx={{ flexShrink: 1 }} spacing={1}>
            <Grid item xs={6}>
              {
                uploads
                  ? <Autocomplete
                    disablePortal
                    id="upload-select"
                    size="small"
                    isOptionEqualToValue={(option, value) => {
                      return value && option.id === value.id
                    }}
                    getOptionLabel={option => option.name}
                    value={upload || null}
                    options={uploads}
                    blurOnSelect={true}
                    onChange={uploadSelected}
                    renderInput={(params) => {
                      return (
                        <TextField {...params} label='Select a Video Upload' placeholder="Select a Video Upload"/>
                      )
                    }}
                  />
                  : <Skeleton variant="rectangular" width='100%' height='100%'/>
              }
            </Grid>
            <Grid item xs={6}>
              {
                highlights
                  ? <Autocomplete
                    id="highlights-select"
                    size="small"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.name}
                    value={highlight || null}
                    options={highlights || []}
                    blurOnSelect={true}
                    disabled={!highlights}
                    onChange={highlightSelected}
                    renderInput={(params) => {
                      return (
                        <TextField {...params} label='Select a Highlight to View'
                                   placeholder="Select a Highlight to View"/>
                      )
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name} <HighlightDecorator value={option}/>
                        </li>
                      )
                    }}
                  />
                  : <Skeleton variant="rectangular" width='100%' height='100%'/>
              }
            </Grid>
            <Grid item xs={12}>
              {
                upload && !upload.url
                  ? <Alert severity="info">
                    This video has not yet finished processing, please check back later!
                  </Alert>
                  : null
              }
              {
                upload && upload.url
                  ? <div style={{ background: 'black' }}>
                    <VideoJS
                      options={{
                        autoplay: false,
                        controls: true,
                        responsive: true,
                        fluid: true,
                        preload: 'metadata',
                        aspectRatio: '16:9',
                        playbackRates: [0.25, 0.5, 1, 1.5, 2, 5],
                        sources: upload.url,
                        withCredentials: false,
                        debug: true
                      }}
                      onReady={handlePlayerReady}
                      onTimeChange={timeUpdated}
                      onRateChange={rateUpdated}
                      onQualityChange={qualityChanged}
                      onMark={markSet}
                      onClear={cleared}
                      onEdit={() => onEditHighlight()}
                      pauseEvents={eventsPaused}
                      debug='true'/>
                  </div>
                  : null
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          {/* Sidebar */}
          <PlayerStatusCard
            uploadId={uploadId}
            currentTime={currentTime}
            currentRemaining={currentRemaining}
            currentRate={currentRate}
            resolution={currentQuality ? `${currentQuality.height} x ${currentQuality.width}` : ''}
            mark={markRef.current}
            onMark={() => {
              markSet(playerRef.current.currentTime())
            }}
            onMarkEnd={() => {
              markSet(playerRef.current.currentTime())
            }}
            onMarkCancel={() => {
              cleared()
            }}
            onHelp={onShowHelp}
          />
          {
            upload
              ? <UploadCard
                upload={upload}
                onEdit={onEditUpload}
              />
              : null
          }
          <Box sx={{ flexGrow: 1, flexDirection: 'column' }}>
            {
              highlight
                ? <HighlightCard
                  value={highlight}
                  onEdit={onEditHighlight}
                  onDelete={onDeleteHighlight}
                  onRefresh={onRefreshHighlight}
                  onPrev={onPrev}
                  onNext={onNext}
                  isPrev={highlights.findIndex(item => item.id === highlight.id) - 1 >= 0}
                  isNext={highlights.findIndex(item => item.id === highlight.id) + 1 < highlights.length}
                />
                : null
            }
          </Box>
        </Grid>
      </Grid>
      {
        newHighlight
          ? <HighlightDialog
            isOpen={true}
            value={newHighlight}
            onSave={onCreateHighlight}
            onCancel={onCancelCreateHighlight}
          />
          : null
      }
      {
        editingUpload
          ? <UploadDialog
            title="Edit Upload"
            isOpen={true}
            upload={upload}
            onSave={onSaveUpload}
            onCancel={onCancelSaveUpload}
          />
          : null
      }
      {
        showHelp
          ? <ControlsDialog
            isOpen={showHelp}
            onClose={onHelpClose}/>
          : null
      }
      {
        editingHighlight
          ? <HighlightDialog
            title="Edit Highlight"
            isOpen={true}
            value={highlight}
            onSave={onSaveHighlight}
            onCancel={onCancelSaveHighlight}
          />
          : null
      }
      <Messages message={message} onClose={() => setMessage(null)}/>
    </>
  )
}

export default Upload
