import React, { useEffect, useRef, useState } from 'react'
import { createUpload, listUserGroups } from '../service'
import { API, Auth } from 'aws-amplify'
import { Button, Grid, Stack, TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import LoadingButton from '@mui/lab/LoadingButton'
import UploadIcon from '@mui/icons-material/VideoCall'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import axios from 'axios'
import GroupOptions from '../components/common/GroupOptions'
import Messages from '../components/common/Messages'
import { DateTime } from 'luxon'

const API_NAME = 'uploadapi'
const PATH = '/uploadapi'

export default function UploadFile () {
  const [name, setName] = useState('')
  const [message, setMessage] = useState(null)
  const [dateTime, setDateTime] = useState(DateTime.now())
  const [description, setDescription] = useState()
  const [uploadUrl, setUploadUrl] = useState(null)
  const [s3Uri, setS3Uri] = useState(null)
  const [file, setFile] = useState(null)
  const fileComponent = useRef(null)
  const [groups, setGroups] = useState(null)
  const [selectedGroups, setSelectedGroups] = useState(null)
  const [loading, setLoading] = useState(false)

  const constructHeaders = async (params = {}) => {
    return Object.assign({}, {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    }, params)
  }

  useEffect(() => {
    const getGroups = async () => {
      setGroups(await listUserGroups())
    }
    getGroups()
  }, [])

  useEffect(() => {
    // TODO: Need to make the content type more generic.

    if (!file) {
      return
    }

    // if (file.size / 1000 > 1100) {
    //   setMessage({
    //     severity: 'error',
    //     text: `The file being uploaded is too large: ${file.size}`
    //   })
    //   return
    // }

    async function getUploadUrl () {
      const myInit = {
        headers: await constructHeaders(),
        queryStringParameters: {
          name: file.name,
          contentType: 'video/quicktime'
        }
      }
      try {
        const data = await API.get(API_NAME, PATH, myInit)
        console.log(`Retrieved upload url: ${data.uploadUrl}`)
        setUploadUrl(data.uploadUrl)
        setS3Uri(data.s3Uri)
      } catch (err) {
        setMessage({
          text: `Error while retrieving upload URL: ${err.toString()}`,
          severity: 'error'
        })
        console.log(err)
      }
    }

    getUploadUrl()
  }, [file])

  const fileChanged = async (ev) => {
    setFile(ev.target.files[0])
  }

  const onGroupChange = async ev => {
    const groupId = ev.target.id
    if (!selectedGroups) {
      setSelectedGroups([groupId])
    } else {
      const index = selectedGroups.indexOf(groupId)
      if (index === -1) {
        setSelectedGroups(selectedGroups.concat([groupId]))
      } else {
        setSelectedGroups(selectedGroups.filter(g => g !== groupId))
      }
    }
  }

  const handleUpload = async () => {
    try {
      setLoading(true)
      const response = await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': 'video/quicktime'
        }
      })

      // Debug
      console.log(`Upload Result: ${JSON.stringify(response, null, 2)}`)

      const created = await createUpload({
        name,
        date: new Date(dateTime),
        description,
        source: s3Uri,
        groups: selectedGroups
      })
      console.log(`Created new upload with id: ${created.id}`)

      setMessage({
        text: `Successfully uploaded file: ${file.name} for processing.`,
        timeout: -1
      })
      fileComponent.current.value = ''
      setFile(null)
      setUploadUrl(null)
    } catch (err) {
      setMessage({
        text: `Error while uploading file: ${err.toString()}`,
        severity: 'error'
      })
      console.log(err.response.data)
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Stack sx={{ paddingLeft: '1em' }} spacing={0} rowGap={2}>
        <div>
          <h1>
            Upload a Video
          </h1>
        </div>

        <Grid container>
          {/* Column 1 */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  aria-label="upload picture"
                  disabled={loading}
                  component="label">
                  <input id="file" ref={fileComponent} hidden accept="video/*" type="file" onChange={fileChanged}/>
                  <UploadIcon/>
                </Button>
                &nbsp;
                <label htmlFor="file">
                  {
                    file ? file.name : 'Select a file to upload'
                  }
                </label>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ width: 500 }}
                  required
                  id="name"
                  label="Name"
                  value={name}
                  disabled={loading}
                  onChange={ev => setName(ev.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  id="dateTime"
                  slotProps={{ textField: { variant: 'outlined' } }}
                  label="Date/Time"
                  value={dateTime}
                  disabled={loading}
                  onChange={(newValue) => {
                    setDateTime(newValue)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description"
                  label="Description"
                  multiline
                  rows={3}
                  value={description}
                  disabled={loading}
                  sx={{ width: 500 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={ev => setDescription(ev.target.value)}
                />
              </Grid>
              <Grid item xs={12} sx={{ alignProperty: 'right' }}>
                {
                  uploadUrl
                    ? <LoadingButton
                      variant="contained"
                      color="primary"
                      onClick={handleUpload}
                      loading={loading}
                      startIcon={<CloudUploadIcon/>}
                    >
                      Upload
                    </LoadingButton>
                    : null
                }
              </Grid>
            </Grid>
          </Grid>

          {/* Column 2 */}
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {
                  groups
                    ? <>
                      <GroupOptions
                        groups={groups}
                        selectedGroups={selectedGroups}
                        onGroupChange={onGroupChange}
                      />
                    </>
                    : null
                }
              </Grid>

            </Grid>

          </Grid>
        </Grid>
      </Stack>

      <Messages message={message} onClose={() => setMessage(null)}/>
    </>
  )
}
