import * as React from 'react'
import * as PropTypes from 'prop-types'
import { useAuthenticator } from '@aws-amplify/ui-react'

Feature.propTypes = {
  flag: PropTypes.string,
  children: PropTypes.any
}

const STATS = 'STATS'

export default function Feature (props) {
  const authenticator = useAuthenticator()
  const FLAGS = new Map([
    [STATS, authenticator.user.username === 'blueshirts' || authenticator.user.username === 'xtremeflyerscoach']
  ])

  const {
    flag
  } = props
  return (
    <>
      {
        FLAGS.has(flag)
          ? <>{props.children}</>
          : null
      }
    </>
  )
}

Object.defineProperty(Feature, STATS, {
  value: STATS,
  configurable: false,
  enumerable: false,
  writable: false
})
