import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, Chip, TextField } from '@mui/material'

TagsEdit.propTypes = {
  tags: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string
}
export default function TagsEdit (props) {
  const {
    tags,
    options,
    onChange,
    placeholder,
    label
  } = props
  return (
    <>
      <Autocomplete
        multiple
        options={options}
        value={tags}
        onChange={onChange}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.id}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label || 'Tags'}
            placeholder={placeholder}
          />
        )}
      />
    </>
  )
}
