import React from 'react'
import styles from '../../css/Highlight.module.css'
import PropTypes from 'prop-types'
import { Grid, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import GroupOptions from '../common/GroupOptions'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DateTime } from 'luxon'

UploadForm.propTypes = {
  title: PropTypes.string,
  upload: PropTypes.object,
  tags: PropTypes.array,
  user: PropTypes.object,
  groups: PropTypes.array,
  onNameChange: PropTypes.func,
  onDescriptionChange: PropTypes.func,
  onDateChange: PropTypes.func,
  onGroupChange: PropTypes.func
}

export default function UploadForm (props) {
  const {
    title,
    upload,
    groups,
    onNameChange,
    onDescriptionChange,
    onDateChange,
    onGroupChange
  } = props

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    autoFocus
                    className={styles.input}
                    variant="outlined"
                    value={upload.name || ''}
                    onChange={ev => onNameChange ? onNameChange(ev) : null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    label="Description"
                    multiline
                    rows={4}
                    className={styles.input}
                    value={upload.description}
                    onChange={ev => onDescriptionChange ? onDescriptionChange(ev) : null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateTimePicker
                    id="dateTime"
                    slotProps={{ textField: { variant: 'outlined' } }}
                    label="Date/Time"
                    value={upload.date ? DateTime.fromISO(upload.date) : null}
                    onChange={ev => onDateChange ? onDateChange(ev) : null}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              {
                groups
                  ? <>
                    <GroupOptions
                      groups={groups}
                      selectedGroups={upload.groups}
                      onGroupChange={onGroupChange}
                    />
                  </>
                  : null
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
