import styles from '../../css/Card.module.css'
import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import HelpIcon from '@mui/icons-material/Help'
import IconButton from '@mui/material/IconButton'

StatusCard.propTypes = {
  uploadId: PropTypes.string,
  currentTime: PropTypes.number,
  currentRemaining: PropTypes.number,
  resolution: PropTypes.string,
  mark: PropTypes.number,
  onMark: PropTypes.func,
  onMarkEnd: PropTypes.func,
  onMarkCancel: PropTypes.func,
  onHelp: PropTypes.func
}

export default function StatusCard ({
  uploadId, currentTime, currentRemaining, resolution, mark, onMark, onMarkEnd, onMarkCancel, onHelp
}) {
  const markSet = ev => {
    if (onMark) {
      onMark(ev)
    }
  }

  const markEnd = ev => {
    if (onMarkEnd) {
      onMarkEnd(ev)
    }
  }

  const markCancel = ev => {
    if (onMarkCancel) {
      onMarkCancel(ev)
    }
  }

  return (
    <Card className={styles.card}>
    <CardContent>
      <Typography className={styles.cardTitle} gutterBottom>
        Player Options<br/>
        {mark
          ? <>
            <Tooltip title="Cancel the current mark.">
              <IconButton color="secondary" size="small" onClick={markCancel}>
                <CancelIcon fontSize="small"/>
              </IconButton>
            </Tooltip>
            <Tooltip title="End the current mark.">
              <IconButton color="error" size="small" onClick={markEnd}>
                <StopCircleIcon fontSize="small"/>
              </IconButton>
            </Tooltip>
          </>
          : <Tooltip title="Start a new highlight.">
              <span>
                <IconButton color="primary" size="small" onClick={markSet} disabled={!uploadId}>
                  <BookmarkAddIcon fontSize="small"/>
                </IconButton>
              </span>
          </Tooltip>}
        <Tooltip title="Controls and shortcut help.">
          <IconButton color="secondary" size="small" onClick={onHelp}>
            <HelpIcon fontSize="small"/>
          </IconButton>
        </Tooltip>
      </Typography>
      {mark
        ? <Typography variant="body2" color="text.secondary">
          Mark Set: {mark} <CheckCircleIcon sx={{
          fontSize: 'small',
          justifyContent: 'center',
          alignItems: 'center',
          verticalAlign: 'middle',
          marginBottom: '.2rem'
        }}/>
        </Typography>
        : null}
      <Typography variant="body2" color="text.secondary">
        <label>
          Elapsed Time
        </label> {currentTime}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <label>
          Remaining Time
        </label> {currentRemaining}
      </Typography>
      <Typography variant="body2" color="text.secondary">
      <label>
        Resolution
      </label> {resolution}
    </Typography>

    </CardContent>
  </Card>)
}
