import * as React from 'react'
import { Button, Tooltip } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import LinkPopover from '../common/LinkPopover'
import PropTypes from 'prop-types'

ShareButton.propTypes = {
  url: PropTypes.string
}

export default function ShareButton (props) {
  const { url } = props
  const [popoverEl, setPopoverEl] = React.useState(null)

  const shareClicked = ev => {
    navigator.clipboard
      .writeText(`${process.env.REACT_APP_REDIRECT_URL}${url}`)
    setPopoverEl(ev.target)
    setTimeout(() => {
      setPopoverEl(null)
    }, 2000)
    return false
  }

  return (
    <>
      <Tooltip title="Get a shareable link.">
        <Button
          size="small"
          color="primary"
          onClick={shareClicked}
        >
          <LinkIcon fontSize="small"/>&nbsp;Share
        </Button>
      </Tooltip>
      <LinkPopover el={popoverEl} setEl={setPopoverEl}/>
    </>
  )
}
