import React from 'react'
import PenaltyIcon from '@mui/icons-material/Sports'
import FaceOffIcon from '@mui/icons-material/SportsHockey'
import GoalIcon from '@mui/icons-material/Campaign'
import InjuryIcon from '@mui/icons-material/LocalHospital'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'
import styles from '../../css/Highlight.module.css'

const HighlightDecorator = props => {
  const { value } = props
  const name = value.name.toLowerCase()
  if (name.indexOf('penalty') !== -1) {
    return (
      <Tooltip title="Penalty">
        <span>&nbsp;<PenaltyIcon className={styles.decorator} color="primary"/></span>
      </Tooltip>
    )
  } else if (name.indexOf('faceoff') !== -1 || name.indexOf('face-off') !== -1) {
    return (
      <Tooltip title="Face-Off">
        <span>&nbsp;<FaceOffIcon className={styles.decorator} color="primary"/></span>
      </Tooltip>
    )
  } else if (name.indexOf('goal') !== -1 && name.indexOf('goalie') === -1) {
    return (
      <Tooltip title="Goal!">
        <span>&nbsp;<GoalIcon className={styles.decorator} color="error"/></span>
      </Tooltip>
    )
  } else if (name.indexOf('injury') !== -1 || name.indexOf('injured') !== -1) {
    return (
      <Tooltip title="Injury">
        <span>&nbsp;<InjuryIcon className={styles.decorator} color="red"/></span>
      </Tooltip>
    )
  } else {
    return null
  }
}

HighlightDecorator.propTypes = {
  value: PropTypes.object
}
export default HighlightDecorator
