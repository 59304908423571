import React, { useEffect, useState } from 'react'
import { listHighlightsByMonth } from '../service'
import { Grid, Stack, Typography } from '@mui/material'
import HighlightPreviewCard from '../components/card/HighlightPreviewCard'

const Highlight = () => {
  const [highlights, setHighlights] = useState()

  useEffect(() => {
    const loadTags = async () => {
      try {
        setHighlights(await listHighlightsByMonth({}))
      } catch (err) {
        console.error(err)
        window.alert(`Error while retrieving highlights: ${err}`)
      }
    }
    loadTags()
  }, [])

  return (
    <Stack sx={{ marginLeft: '.5em' }} spacing={1}>
      <Typography variant="h5">
        Your Highlights Stream
      </Typography>
      {/* <br/> */}
      <Stack spacing={0} rowGap={2}>
        {
          highlights
            ? highlights.map(highlight => (
              <Grid container key={highlight.id} spacing={3}>
                <Grid item xs={12} md={8} lg={6}>
                  <HighlightPreviewCard highlight={highlight}/>
                </Grid>
              </Grid>
            ))
            : null
        }
      </Stack>
    </Stack>
  )
}

export default (Highlight)
