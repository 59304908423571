import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  Link,
  Typography
} from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import UploadCardMedia from './UploadCardMedia'
import ShareButton from './ShareButton'
import ViewButton from './ViewButton'
import Delimeter from '../common/Delimeter'

HighlightPreviewCard.propTypes = {
  highlight: PropTypes.object
}

export default function HighlightPreviewCard (props) {
  const { highlight } = props

  return (
    <>
      <Card sx={{ maxWidth: '100%' }}>
        <CardActionArea onClick={() => {
          window.location = `/upload/${highlight.upload.id}/highlight/${highlight.id}`
        }}>
          <UploadCardMedia upload={highlight.upload}/>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {highlight.name}
            </Typography>
            {
              highlight.description
                ? <>
                  <Typography variant="body2" color="text.secondary">
                    {highlight.description}
                  </Typography>
                  <br/>
                </>
                : null
            }
            <Typography gutterBottom variant="body2" color="text.secondary" component="span">
              Upload: <Link href={`/upload/${highlight.upload.id}`}>{highlight.upload.name}</Link>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Author: {highlight.owner}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Groups: <Delimeter values={highlight.groups}/>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Tags:&nbsp;
              {
                highlight.tags.items.map((tag, index) => (
                  <span key={tag.id}>
                  <Chip
                    label={tag.name}
                    href={`/tag/${tag.name}`}
                    component="a"
                    clickable
                    variant="outlined"
                    size="small"/>
                    {index + 1 < highlight.tags.items.length ? <span>&nbsp;</span> : null}
                </span>
                ))
              }
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Created: {DateTime.fromISO(highlight.createdAt).toLocaleString(DateTime.DATETIME_MED)}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <ViewButton url={`/upload/${highlight.uploadHighlightsId}/highlight/${highlight.id}`} />
          <ShareButton url={`/upload/${highlight.uploadHighlightsId}/highlight/${highlight.id}`} />
        </CardActions>
      </Card>
    </>
  )
}
