import * as React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { listStatsByMonth } from '../service'
import Stats from '../stats/Stats'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material'
import Box from '@mui/material/Box'

export default function StatsView (props) {
  const [data, setData] = useState()
  const [stats, setStats] = useState(null)
  const params = useParams()

  useEffect(() => {
    const loadStats = async () => {
      setData(await listStatsByMonth())
    }
    loadStats()
      .catch(err => {
        console.dir(err)
      })
  }, [])

  useEffect(() => {
    setStats(Stats(data))
  }, [data])

  if (stats && stats.players) {
    console.log(JSON.stringify(Array.from(stats.players), null, 2))
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Player</TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={true}
                  direction="desc"
                  // onClick={createSortHandler(headCell.id)}
                >
                  {/* "Asdfasdf" */}
                  {/* {1 === 1 */}
                  {/*  ? ( */}
                  {/*  <Box component="span" sx={false}> */}
                  {/*    {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                  {/*  </Box> */}
                  {/*    ) */}
                  {/*  : null} */}
                </TableSortLabel>
                G
              </TableCell>
              <TableCell align="right">A</TableCell>
              <TableCell align="right">P</TableCell>
              <TableCell align="right">+/-</TableCell>
              <TableCell align="right">ESG</TableCell>
              <TableCell align="right">ESA</TableCell>
              <TableCell align="right">ESP</TableCell>
              <TableCell align="right">PPG</TableCell>
              <TableCell align="right">PPA</TableCell>
              <TableCell align="right">PPP</TableCell>
              <TableCell align="right">PEN</TableCell>
              <TableCell align="right">PIM</TableCell>
              <TableCell align="right">FO</TableCell>
              <TableCell align="right">FOW%</TableCell>
            </TableRow>
          </TableHead>
          {
            stats && stats.players
              ? <TableBody>
                {
                  Array.from(stats.players.values()).map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.goals}</TableCell>
                      <TableCell align="right">{row.assists}</TableCell>
                      <TableCell align="right">{row.points}</TableCell>
                      <TableCell align="right">{row.plusMinus}</TableCell>
                      <TableCell align="right">{row.evenStrengthGoals}</TableCell>
                      <TableCell align="right">{row.evenStrengthAssists}</TableCell>
                      <TableCell align="right">{row.evenStrengthPoints}</TableCell>
                      <TableCell align="right">{row.powerPlayGoals}</TableCell>
                      <TableCell align="right">{row.powerPlayAssists}</TableCell>
                      <TableCell align="right">{row.powerPlayPoints}</TableCell>
                      <TableCell align="right">{row.penalties}</TableCell>
                      <TableCell align="right">{row.pims}</TableCell>
                      <TableCell align="right">{row.faceoffs}</TableCell>
                      <TableCell align="right">{row.faceoffPercentage ? Number(row.faceoffPercentage).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0 }) : ''}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              : null
          }
        </Table>
      </TableContainer>
    </>
  )
}
