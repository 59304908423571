import * as React from 'react'
import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { Auth } from 'aws-amplify'
import HighlightForm from './HighlightForm'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'

HighlightDialog.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  value: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
}

export default function HighlightDialog (props) {
  const {
    title = 'New Highlight',
    isOpen,
    value = {},
    onSave,
    onCancel
  } = props

  const [open, setOpen] = useState(isOpen)
  const [highlight, setHighlight] = useState(value)
  const [user, setUser] = useState(null)
  const [tags, setTags] = useState(value.tags ? value.tags.items.map(item => item.name) : [])
  const [stats, setStats] = useState(value.stats ? value.stats.items.map(item => item.name) : [])
  const [groups, setGroups] = useState(null)

  useEffect(() => {
    const getUserGroups = async () => {
      const user = await Auth.currentAuthenticatedUser()
      setUser(user)
      const a = user.signInUserSession.accessToken.payload['cognito:groups']
      setGroups(a)
    }
    getUserGroups()
  }, [])

  const onNameChange = ev => {
    setHighlight(Object.assign({}, highlight, { name: ev.target.value }))
  }

  const onDescriptionChange = ev => {
    setHighlight(Object.assign({}, highlight, { description: ev.target.value }))
  }

  const onStartChange = ev => {
    setHighlight(Object.assign({}, highlight, { start: ev.target.value }))
  }

  const onEndChange = ev => {
    setHighlight(Object.assign({}, highlight, { end: ev.target.value }))
  }

  const onTagsChange = (ev, newValues) => {
    setTags(newValues)
  }

  const onStatsChange = (ev, newValues) => {
    setStats(newValues)
  }

  const onGroupChange = ev => {
    const group = ev.target.id
    if (groups.indexOf(group) !== -1) {
      // User has this group, can be added or removed.
      if (!highlight.groups) {
        setHighlight(Object.assign({}, highlight, { groups: [group] }))
      } else if (ev.target.value === 'on' && highlight.groups.indexOf(group) === -1) {
        const temp = highlight.groups ? highlight.groups : []
        temp.push(group)
        setHighlight(Object.assign({}, highlight, { groups: temp }))
      } else {
        const temp = highlight.groups.filter(item => item !== group)
        setHighlight(Object.assign({}, highlight, { groups: temp }))
      }
    }
  }

  const handleSave = ev => {
    if (!highlight.month) {
      highlight.month = DateTime.now().toFormat('yyyy-MM')
    }

    // Ensure the new tags are formatted properly.
    const newTags = []
    for (const tag of tags) {
      const existingTag = value.tags ? value.tags.items.find(item => (item.name === tag)) : null
      if (existingTag) {
        newTags.push(existingTag)
      } else {
        newTags.push({ name: tag, groups: highlight.groups })
      }
    }

    // Ensure the new stats are formatted properly.
    const newStats = []
    for (const stat of stats) {
      const existingStat = value.stats ? value.stats.items.find(item => (item.name === stat)) : null
      if (existingStat) {
        newStats.push(existingStat)
      } else {
        newStats.push({ name: stat, groups: highlight.groups })
      }
    }

    // There should be an item property that contains the tags for GraphQL.
    if (highlight.tags) {
      highlight.tags.items = newTags
    } else {
      highlight.tags = {
        items: newTags
      }
    }
    if (highlight.stats) {
      highlight.stats.items = newStats
    } else {
      highlight.stats = {
        items: newStats
      }
    }
    if (onSave) {
      onSave(ev, highlight)
    }
    setOpen(false)
  }

  const handleCancel = (ev, reason) => {
    if (reason === 'backdropClick') {
      return
    } else if (onCancel) {
      onCancel(ev, highlight)
    }
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter or update the details for the highlight and click save.
          </DialogContentText>
          <HighlightForm
            user={user}
            groups={groups}
            value={highlight}
            tags={tags}
            stats={stats}
            onNameChange={onNameChange}
            onDescriptionChange={onDescriptionChange}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
            onGroupChange={onGroupChange}
            onTagsChange={onTagsChange}
            onStatsChange={onStatsChange}
          />
        </DialogContent>
        <DialogActions sx={{ marginBottom: '1em', marginRight: '1em' }}>
          <Button color="secondary" variant="outlined" onClick={handleCancel}>
            <CancelIcon fontSize="x-small"/>&nbsp;Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleSave}>
            <SaveIcon fontSize="x-small"/>&nbsp;Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
