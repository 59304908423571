import * as React from 'react'
import PropTypes from 'prop-types'
import { CardMedia } from '@mui/material'

const getThumbnail = upload => {
  const startingPercentage = Math.random() * 10
  const thumbnailIndex = Math.floor(startingPercentage)
  const source = upload.source
  const name = source.substring(source.lastIndexOf('/') + 1, source.length)
  const user = name.substring(0, name.indexOf('-'))
  const file = name.substring(name.indexOf('-') + 1, name.length)
  return `https://highlights-video-storage.s3.amazonaws.com/${user}/${file}.000000${thumbnailIndex}.jpg`
}

UploadCardMedia.propTypes = {
  upload: PropTypes.object
}

export default function UploadCardMedia (props) {
  const { upload } = props

  const updatePreview = (ev, highlight) => {
    ev.target.src = getThumbnail(highlight)
  }

  return (
    <>
      <CardMedia
        component="img"
        height="300"
        image={getThumbnail(upload)}
        onMouseOver={ev => {
          updatePreview(ev, upload)
        }}
        onMouseOut={ev => {
          updatePreview(ev, upload)
        }}
        onError={err => {
          err.target.src = '/default-preview.jpg'
        }}
        alt="Upload Preview"
      />
    </>
  )
}
