import * as React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material'

TagOptions.propTypes = {
  values: PropTypes.array,
  nameField: PropTypes.string,
  urlFn: PropTypes.func
}

export default function TagOptions (props) {
  const {
    values,
    nameField,
    urlFn
  } = props

  return (
    <>
      {
        values
          ? values.map((item, index) => (
          <span key={item.id}>
                  <Chip
                    label={item[nameField || 'name']}
                    href={urlFn ? urlFn(item) : null}
                    component="a"
                    clickable
                    variant="outlined"
                    size="small"/>
            {index + 1 < values.length ? <span>&nbsp;</span> : null}
                </span>
          ))
          : null
      }
    </>
  )
}
