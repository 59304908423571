import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

GroupOptions.propTypes = {
  groups: PropTypes.array,
  selectedGroups: PropTypes.array,
  onGroupChange: PropTypes.func
}

export default function GroupOptions (props) {
  const { groups, selectedGroups, onGroupChange } = props

  const groupState = []
  for (const g of groups) {
    const item = {
      name: g
    }
    if (selectedGroups && selectedGroups.indexOf(g) !== -1) {
      item.checked = true
    } else {
      item.checked = false
    }
    groupState.push(item)
  }

  return (
    <>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">Share with Groups</FormLabel>
      </FormControl>
      <FormGroup onChange={ev => onGroupChange ? onGroupChange(ev) : null}>
        {
          groupState.map(item => {
            return (
              <FormControlLabel
                key={item.name}
                control={
                  <Checkbox
                    id={item.name}
                    name="groups"
                    checked={item.checked}
                    size="small"
                  />
                }
                label={item.name}/>
            )
          })
        }
      </FormGroup>
    </>
  )
}
