import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { listTagsByMonth } from '../service'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Grid, Stack, Typography } from '@mui/material'
import HighlightPreviewCard from '../components/card/HighlightPreviewCard'

const Tag = () => {
  const authenticator = useAuthenticator()
  const params = useParams()
  const { tag } = params
  const [tags, setTags] = useState()

  useEffect(() => {
    const loadTags = async () => {
      try {
        setTags(await listTagsByMonth({ tag, username: authenticator.user.username }))
      } catch (err) {
        console.error(err)
        window.alert('Error while retrieving tagged highlights.')
      }
    }
    loadTags()
  }, [])

  return (
    <Stack sx={{ marginLeft: '.5em' }} spacing={1}>
      <Typography variant="h5">
        Your Tagged Highlights Stream
      </Typography>
      {/* <br/> */}
      <Stack spacing={0} rowGap={2}>
        {
          tags
            ? tags.map(tag => (
              <Grid container key={tag.id} spacing={3}>
                <Grid item xs={12} md={8} lg={6}>
                  <HighlightPreviewCard highlight={tag.highlight}/>
                </Grid>
              </Grid>
            ))
            : null
        }
      </Stack>
    </Stack>
  )
}

export default (Tag)
