
const _NumberComparator = (a, b) => {
  return a - b
}

const NumberComparator = (field = null) => {
  if (field) {
    return (a, b) => {
      return a[field] - b[field]
    }
  } else {
    return (a, b) => a - b
  }
}

export default {
  NumberComparator
}
