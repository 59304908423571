import * as React from 'react'
import { Link } from '@mui/material'
import PropTypes from 'prop-types'

Delimeter.propTyles = {
  values: PropTypes.array,
  field: PropTypes.string,
  linkFn: PropTypes.func,
  delimiter: PropTypes.string,
  sortDirection: PropTypes.string,
  sortField: PropTypes.string
}

export default function Delimeter (params) {
  const { values, field, linkFn, delimeter = ',', sortDirection, sortField } = params
  if (values && !Array.isArray(values)) {
    // Error
    throw new Error(`values is not an array: ${values}`)
  }
  let a = values
  if (values && sortDirection && sortField) {
    if (sortDirection === 'A') {
      a = values.sort((a, b) => (a[sortField].localeCompare(b[sortField])))
    }
  }
  if (!a) {
    return (
      <></>
    )
  } else if (linkFn) {
    return (
      <>
        {
          a.map((item, index) => {
            return (
              <Link key={index} href={linkFn(item)}>
              {field ? item[field] : item.toString()}
                {index + 1 !== values.length ? <>{delimeter}&nbsp;</> : null}
            </Link>
            )
          })}
      </>
    )
  } else {
    return (
      <>
        {
          a.map((item, index) => {
            return (
            <span key={index}>
              {field ? item[field] : item.toString()}
              {index + 1 !== values.length ? <>{delimeter}&nbsp;</> : null}
            </span>
            )
          })}
      </>
    )
  }
}
