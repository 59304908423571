import { API, Auth, graphqlOperation } from 'aws-amplify'
import { getHighlight, getUpload, listHighlights, listUploads, statsByMonth } from '../graphql/queries'
import { highlightsByMonth, tagsByMonth } from './custom-queries'
import {
  createHighlight,
  createTag,
  createStats,
  createUpload,
  updateUpload,
  deleteHighlight,
  deleteTag,
  deleteStats,
  updateHighlight
} from '../graphql/mutations'
import { DateTime } from 'luxon'

const MONTH_FORMAT = 'yyyy-MM'
const COGNITO_POOL_ID = 'a285ec47-739b-4133-96d8-442ee36c39e4'
const MAX_RESULT = 250

const sanitizeHighlight = o => {
  if (o) {
    const { createdAt, updatedAt, _lastChangedAt, __typename, _deleted, upload, tags, stats, ...sanitized } = o
    return sanitized
  } else {
    return o
  }
}

const sanitizeUpload = u => {
  if (u) {
    const { createdAt, updatedAt, _lastChangedAt, __typename, _deleted, highlights, ...sanitized } = u
    return sanitized
  } else {
    return u
  }
}

// const sanitizeTag = t => {
//   if (t) {
//     const { createdAt, updatedAt, _lastChangedAt, __typename, _deleted, highlight, ...sanitized } = t
//     return sanitized
//   } else {
//     return t
//   }
// }

const getUploadFn = async (id) => {
  const result = await API.graphql(graphqlOperation(getUpload, { id }))
  return result.data.getUpload
}

const createUploadFn = async (u) => {
  const result = await API.graphql(graphqlOperation(createUpload, { input: u }))
  return result.data.createUpload
}

const updateUploadFn = async (u) => {
  const result = await API.graphql(graphqlOperation(updateUpload, { input: sanitizeUpload(u) }))
  return result.data.updateUpload
}

// const updateUploadUrlFn = async (source, url) => {
//   const result = await API.graphql(graphqlOperation(updateHighlight, { input: { url } }))
//   return result.data.updateHighlight
// }

const listUploadsFn = async () => {
  const result = await API.graphql({
    query: listUploads,
    variables: {
      limit: MAX_RESULT * 2
    }
  })
  // return result.data.listUploads.items.sort((a, b) => a.name.localeCompare(b.name))
  return result.data.listUploads.items.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime()
  })
}

const listHighlightsFn = async (uploadId) => {
  const result = await API.graphql({
    query: listHighlights,
    variables: {
      limit: 500,
      filter: {
        uploadHighlightsId: { eq: uploadId }
      }
    }
  })
  return result.data.listHighlights.items.sort((a, b) => a.start - b.start)
}

const getHighlightFn = async (id) => {
  const result = await API.graphql(graphqlOperation(getHighlight, { id }))
  return result.data.getHighlight
}

const createHighlightFn = async (h) => {
  let resultErr = null
  const result = await API.graphql(graphqlOperation(createHighlight, { input: sanitizeHighlight(h) }))
  if (result.data.errors && result.data.errors.length > 0) {
    // Error
    throw new Error(`Exception while saving new highlight: ${JSON.stringify(result.data)}`)
  }
  try {
    await addNewHighlightTags(result.data.createHighlight.id, h.tags.items)
  } catch (err) {
    resultErr = err
  }

  try {
    await addNewHighlightStats(result.data.createHighlight.id, h.stats.items)
  } catch (err) {
    resultErr = err
  }

  if (resultErr) {
    throw resultErr
  }
  return result.data.createHighlight
}

const listHighlightsByMonth = async () => {
  const current = DateTime.now()
  const variables = []
  for (let i = 0; i < 6; i++) {
    const month = current.minus({ months: i }).toFormat(MONTH_FORMAT)
    variables.push({
      month,
      limit: MAX_RESULT
    })
  }
  return variables.reduce(reduceHighlightsByMonth, Promise.resolve([]))
}

const compareDates = (a, b) => {
  return new Date(b).getTime() - new Date(a).getTime()
}

const compareStrings = (a, b) => {
  return String(a).localeCompare(b)
}

const compareIntegers = (a, b) => {
  return a - b
}

const reduceHighlightsByMonth = async (acc, current) => {
  return acc.then(async result => {
    if (result.length > MAX_RESULT) {
      // We have already found the max result.
      return result // ** EXIT **
    }
    const listResult = await API.graphql(graphqlOperation(highlightsByMonth, current))
    const sorted = listResult.data.highlightsByMonth.items.sort((a, b) => {
      const i = compareDates(a.upload.date, b.upload.date)
      if (i === 0) {
        const ii = compareStrings(a.id, b.id)
        if (ii === 0) {
          return compareIntegers(a.start, b.start)
        } else {
          return ii
        }
      } else {
        return i
      }
    })
    return result.concat(sorted)
  })
}

const listTagsByMonth = async params => {
  const now = DateTime.now()
  const a = []
  const baseVariables = {
    sortDirection: 'DESC',
    limit: 500,
    filter: {
      owner: {
        eq: `${COGNITO_POOL_ID}::${params.username}`
      }
    }
  }
  if (params.tag) {
    baseVariables.filter = {
      name: {
        eq: params.tag
      }
    }
  }
  for (let i = 0; i < 6; i++) {
    const month = now.minus({ months: i }).toFormat(MONTH_FORMAT)
    const variables = Object.assign({}, baseVariables, { month })
    a.push(variables)
  }
  return a.reduce(reduceTagsByMonth, Promise.resolve([]))
}

const reduceTagsByMonth = (acc, current) => {
  return acc.then(async result => {
    if (result.length > MAX_RESULT) {
      // We have already found the max result.
      return result // ** EXIT **
    }
    const listResult = await API.graphql(graphqlOperation(tagsByMonth, current))
    const highlightIds = new Set()
    for (const tag of listResult.data.tagsByMonth.items) {
      if (!highlightIds.has(tag.highlightTagsId)) {
        highlightIds.add(tag.highlightTagsId)
      }
      result.push(tag)
    }
    return result
  })
}

const listStatsByMonth = async params => {
  const now = DateTime.now()
  const a = []
  const baseVariables = {
    limit: 1000
    // TODO: Filter by groups.
    // filter: {
    //   owner: {
    //     eq: `${COGNITO_POOL_ID}::${params.username}`
    //   }
    // }
  }
  for (let i = 0; i < 6; i++) {
    const month = now.minus({ months: i }).toFormat(MONTH_FORMAT)
    const variables = Object.assign({}, baseVariables, { month })
    a.push(variables)
  }
  return a.reduce(reduceStatsByMonth, Promise.resolve([]))
}

const reduceStatsByMonth = (acc, current) => {
  return acc.then(async result => {
    if (result.length > MAX_RESULT) {
      // We have already found the max result.
      return result // ** EXIT **
    }
    const listResult = await API.graphql(graphqlOperation(statsByMonth, current))
    const highlightIds = new Set()
    for (const stat of listResult.data.statsByMonth.items) {
      if (!highlightIds.has(stat.highlightTagsId)) {
        highlightIds.add(stat.highlightTagsId)
      }
      result.push(stat)
    }
    return result
  })
}

const addNewHighlightTags = async (highlightId, tags) => {
  const now = DateTime.now()
  const created = []
  for (const t of tags) {
    if (!t.id) {
      const newTag = Object.assign({}, t, {
        date: now.toJSDate(),
        month: now.toFormat(MONTH_FORMAT),
        highlightTagsId: highlightId
      })
      // TODO: Add unique constraint check for new tags.
      // "condition": {
      //   "name": {
      //     "ne": "test"
      //   }
      // }
      const result = await API.graphql(graphqlOperation(createTag, { input: newTag }))
      if (result.data.errors) {
        throw new Error(`Error while updating highlight tags: ${JSON.stringify(result.data.errors, null, 2)}`)
      }
      created.push(result.data.createTag)
    }
  }
  return created
}

const addNewHighlightStats = async (highlightId, stats) => {
  const now = DateTime.now()
  const created = []
  for (const s of stats) {
    if (!s.id) {
      const newStat = Object.assign({}, s, {
        date: now.toJSDate(),
        month: now.toFormat(MONTH_FORMAT),
        highlightStatsId: highlightId
      })
      // TODO: Add unique constraint check for new stats.
      // "condition": {
      //   "name": {
      //     "ne": "test"
      //   }
      // }
      const result = await API.graphql(graphqlOperation(createStats, { input: newStat }))
      if (result.data.errors) {
        throw new Error(`Error while updating highlight tags: ${JSON.stringify(result.data.errors, null, 2)}`)
      }
      created.push(result.data.createStats)
    }
  }
  return created
}

const updateHighlightFn = async (h) => {
  const existingHighlight = await getHighlightFn(h.id)
  const existingTags = existingHighlight.tags.items
  const existingStats = existingHighlight.stats.items
  console.log(`Found ${existingTags.length} existing tags for id: ${h.id}`)

  const deletedTags = []
  for (const t of existingTags) {
    console.log(`${t.id} === ${!h.tags.items.find(item => item.id === t.id)}`)
    if (!h.tags.items.find(item => item.id === t.id)) {
      deletedTags.push(t)
    }
  }
  console.log(`Deleting ${deletedTags.length} tags...`)
  for (const t of deletedTags) {
    try {
      const result = await API.graphql(graphqlOperation(deleteTag, {
        input: {
          id: t.id
        }
      }))
      if (result.data.errors) {
        console.error(result.data.errors)
        window.alert('Error while removing tags.')
      }
    } catch (err) {
      console.error(err)
      window.alert('Error while removing tags.')
    }
  }

  const deletedStats = []
  for (const s of existingStats) {
    console.log(`${s.id} === ${!h.stats.items.find(item => item.id === s.id)}`)
    if (!h.stats.items.find(item => item.id === s.id)) {
      deletedStats.push(s)
    }
  }
  console.log(`Deleting ${deletedStats.length} stats...`)
  for (const s of deletedStats) {
    try {
      const result = await API.graphql(graphqlOperation(deleteStats, {
        input: {
          id: s.id
        }
      }))
      if (result.data.errors) {
        console.error(result.data.errors)
        window.alert('Error while removing stats.')
      }
    } catch (err) {
      console.error(err)
      window.alert('Error while removing stats.')
    }
  }

  const tags = await addNewHighlightTags(h.id, h.tags.items)
  console.log(`Created ${tags.length} new tags`)
  const stats = await addNewHighlightStats(h.id, h.stats.items)
  console.log(`Create ${stats.length} new stats`)

  const result = await API.graphql(graphqlOperation(updateHighlight, { input: sanitizeHighlight(h) }))
  return result.data.updateHighlight
}

const deleteHighlightFn = async (id) => {
  const getResult = await API.graphql(graphqlOperation(getHighlight, { id }))
  const highlight = getResult.data.getHighlight

  for (const t of highlight.tags.items) {
    const deleteResult = await API.graphql(graphqlOperation(deleteTag, { input: { id: t.id } }))
    if (deleteResult.data.errors) {
      throw new Error(`Exception while deleting tags: ${JSON.stringify(deleteResult.data.errors, null, 2)}`)
    }
  }

  for (const s of highlight.stats.items) {
    const deleteResult = await API.graphql(graphqlOperation(deleteStats, { input: { id: s.id } }))
    if (deleteResult.data.errors) {
      throw new Error(`Exception while deleting stats: ${JSON.stringify(deleteResult.data.errors, null, 2)}`)
    }
  }

  const result = await API.graphql(graphqlOperation(deleteHighlight, { input: { id } }))
  return result.data.deleteHighlight
}

const listUserGroups = async () => {
  const user = await Auth.currentAuthenticatedUser()
  return user.signInUserSession.accessToken.payload['cognito:groups']
}

export {
  getUploadFn as getUpload,
  createUploadFn as createUpload,
  updateUploadFn as updateUpload,
  listUploadsFn as listUploads,
  listHighlightsFn as listHighlights,
  getHighlightFn as getHighlight,
  createHighlightFn as createHighlight,
  updateHighlightFn as updateHighlight,
  deleteHighlightFn as deleteHighlight,
  listTagsByMonth,
  listHighlightsByMonth,
  listUserGroups,
  listStatsByMonth
}
