import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from '../../css/Card.module.css'
import { Card, CardActionArea, CardActions, CardContent, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { Auth } from 'aws-amplify'
import UploadCardMedia from './UploadCardMedia'
import ShareButton from './ShareButton'
import ViewButton from './ViewButton'

UploadPreviewCard.propTypes = {
  upload: PropTypes.object,
  onEdit: PropTypes.func
}

export default function UploadPreviewCard (props) {
  const [, setCanEdit] = useState(false)
  const [, setCanDelete] = useState(false)
  const {
    upload
  } = props

  useEffect(() => {
    const getUserGroups = async () => {
      const user = await Auth.currentAuthenticatedUser()
      const isOwner = user.username === upload.owner
      const groups = user.signInUserSession.accessToken.payload['cognito:groups']

      if (isOwner) {
        setCanEdit(true)
        setCanDelete(true)
      } else {
        for (let i = 0; i < groups.length; i++) {
          if (upload.groups.find(group => group === groups[i])) {
            setCanEdit(true)
            return
          }
        }
      }
    }
    getUserGroups()
  }, [])

  return (
    <>
      <Card className={styles.card}>
        <CardActionArea onClick={() => {
          window.location = `/upload/${upload.id}`
        }}>
          <UploadCardMedia upload={upload}/>

          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {upload.name}
            </Typography>
            <Typography gutterBottom variant="body1" component="div">
              {upload.date ? DateTime.fromISO(upload.date).toLocaleString(DateTime.DATETIME_MED) : 'NA'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {upload.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <ViewButton url={`/upload/${upload.id}`}/>
          <ShareButton url={`/upload/${upload.id}`}/>
        </CardActions>
      </Card>
    </>
  )
}
