import * as React from 'react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import SaveIcon from '@mui/icons-material/Save'
import { Auth } from 'aws-amplify'
import UploadForm from './UploadForm'

UploadDialog.propTypes = {
  upload: PropTypes.object,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
}
export default function UploadDialog (props) {
  const { isOpen, onSave, onCancel } = props
  const [open, setOpen] = useState(isOpen)
  const [user, setUser] = useState(null)
  const [groups, setGroups] = useState(null)
  const [upload, setUpload] = useState(props.upload)

  useEffect(() => {
    const getUserGroups = async () => {
      const user = await Auth.currentAuthenticatedUser()
      setUser(user)
      const a = user.signInUserSession.accessToken.payload['cognito:groups']
      setGroups(a)
    }
    getUserGroups()
  }, [])

  const handleSave = (ev) => {
    if (onSave) {
      onSave(ev, upload)
    }
    setOpen(false)
  }

  const handleCancel = (ev, reason) => {
    if (reason === 'backdropClick') {
      return
    } else if (onCancel) {
      onCancel(ev, upload)
    }
    setOpen(false)
  }

  const onNameChange = (ev) => {
    setUpload(Object.assign({}, upload, { name: ev.target.value }))
  }

  const onDescriptionChange = (ev) => {
    setUpload(Object.assign({}, upload, { description: ev.target.value }))
  }

  const onDateChange = date => {
    setUpload(Object.assign({}, upload, { date }))
  }

  const onGroupChange = (ev) => {
    const group = ev.target.id
    if (groups.indexOf(group) !== -1) {
      // User has this group, can be added or removed.
      if (!upload.groups) {
        setUpload(Object.assign({}, upload, { groups: [group] }))
      } else if (ev.target.value === 'on' && upload.groups.indexOf(group) === -1) {
        const temp = upload.groups ? upload.groups : []
        temp.push(group)
        setUpload(Object.assign({}, upload, { groups: temp }))
      } else {
        const temp = upload.groups.filter(item => item !== group)
        setUpload(Object.assign({}, upload, { groups: temp }))
      }
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCancel}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{upload ? upload.name : ''}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter or update the details for the upload and click save.
          </DialogContentText>
          <UploadForm
            user={user}
            groups={groups}
            upload={upload}
            onNameChange={onNameChange}
            onDescriptionChange={onDescriptionChange}
            onDateChange={onDateChange}
            onGroupChange={onGroupChange}
          />
        </DialogContent>
        <DialogActions sx={{ marginBottom: '1em', marginRight: '1em' }}>
          <Button color="secondary" variant="outlined" onClick={handleCancel}>
            <CancelIcon fontSize="x-small"/>&nbsp;Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleSave}>
            <SaveIcon fontSize="x-small"/>&nbsp;Save</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
