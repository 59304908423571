import * as React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import ControlsCard from '../card/ControlsCard'

ControlsDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default function ControlsDialog (props) {
  const {
    isOpen,
    onClose
  } = props

  const handleClose = (ev, reason) => {
    if (onClose) {
      onClose(ev, reason)
    }
  }

  return (
      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>Player Controls Help</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              The shortcuts below may be used to control playback of the video. On Windows the CTRL key can be
              substituted for the M key.
            </p>
          </DialogContentText>
          <ControlsCard/>
        </DialogContent>
        <DialogActions sx={{ marginBottom: '1em', marginRight: '1em' }}>
          <Button color="primary" variant="contained" onClick={handleClose}>
            <SaveIcon fontSize="x-small"/>&nbsp;Close</Button>
        </DialogActions>
      </Dialog>
  )
}
