import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import videojs from 'video.js'
import 'videojs-overlay'
import 'videojs-contrib-quality-levels'
import 'video.js/dist/video-js.css'
import styles from '../../css/Video.module.css'

const EDIT_KEY = 'KeyE'
const STOP_KEY = 'KeyK'
const FORWARD_KEY = 'KeyL'
const REVERSE_KEY = 'KeyJ'
const START_STOP_KEY = 'Space'
const SKIP_BACK_KEY = 'ArrowLeft'
const SKIP_FORWARD_KEY = 'ArrowRight'
const VOLUME_UP_KEY = 'ArrowUp'
const VOLUME_DOWN_KEY = 'ArrowDown'
const ENTER_KEY = 'Enter'
const MARK_KEY = 'KeyY'
const CLEAR_KEY = 'Escape'

const FORWARD_SPEED_INTERVAL = 1.5
const SKIP_INTERVAL = 5
const VOLUME_INTERVAL = 0.2

const DEFAULT_PLAYBACK_RATE = 1
const SLOW_PLAYBACK_RATE = 0.5
const SLOW_REVERSE_PLAYBACK_RATE = 0.05
const REVERSE_PLAYBACK_RATE = 0.6
const MAX_PLAYBACK_RATE = 16

VideoJS.propTypes = {
  debug: PropTypes.string,
  options: PropTypes.object,
  onReady: PropTypes.func,
  onLoadMetaData: PropTypes.func,
  onCanPlayThrough: PropTypes.func,
  onTimeChange: PropTypes.func,
  onRateChange: PropTypes.func,
  onMark: PropTypes.func,
  onEdit: PropTypes.func,
  onClear: PropTypes.func,
  pauseEvents: PropTypes.bool,
  onQualityChange: PropTypes.func
}

function VideoJS (props = {}) {
  const {
    debug,
    options,
    onReady,
    onLoadMetaData,
    onCanPlayThrough,
    onTimeChange,
    onRateChange,
    onMark,
    onClear,
    onEdit,
    pauseEvents,
    onQualityChange
  } = props

  const log = s => {
    if (debug === true || debug === 'true') {
      console.log(s)
    }
  }

  const videoRef = useRef(null)
  const playerRef = useRef(null)
  const isReverse = useRef(false)
  const isSlowReverse = useRef(false)

  let keys = {}

  const play = () => {
    if (playerRef.current.paused()) {
      playerRef.current.play()
    }
  }

  const playbackRate = (rate = null) => {
    if (rate === null || playerRef.current.playbackRate() === rate) {
      return playerRef.current.playbackRate()
    } else if (playerRef.current.playbackRate() !== rate) {
      log(`Setting playback rate: ${rate}`)
      return playerRef.current.playbackRate(rate)
    }
  }

  const playbackDefault = () => {
    playbackRate(DEFAULT_PLAYBACK_RATE)
  }

  const playbackSlow = () => {
    playbackRate(SLOW_PLAYBACK_RATE)
    play()
  }

  const _slowReverse = () => {
    setTimeout(() => {
      currentTime(currentTime() - SLOW_REVERSE_PLAYBACK_RATE)
      if (currentTime() === 0) {
        // At the start, play again.
        isSlowReverse.current = false
      } else if (isSlowReverse.current) {
        _slowReverse()
      }
    }, 100)
  }

  const _reverse = () => {
    setTimeout(() => {
      currentTime(currentTime() - REVERSE_PLAYBACK_RATE)
      if (currentTime() === 0) {
        isReverse.current = false
      } else if (isReverse.current) {
        _reverse()
      }
    }, 100)
  }

  const playbackSlowReverse = () => {
    if (!isSlowReverse.current) {
      if (isReverse.current) {
        isReverse.current = false
      }
      isSlowReverse.current = true
      if (!playerRef.current.ended()) {
        playerRef.current.play()
      }
      _slowReverse()
    }
  }

  const playbackReverse = () => {
    if (!isReverse.current) {
      isReverse.current = true
      if (!playerRef.current.ended()) {
        playerRef.current.play()
      }
      _reverse()
    }
  }

  const playbackMax = () => {
    playbackRate(MAX_PLAYBACK_RATE)
  }

  const currentTime = (time) => {
    return playerRef.current.currentTime(time)
  }

  const skipBack = () => {
    const newTime = currentTime() - SKIP_INTERVAL
    log(newTime)
    return currentTime(newTime > 0 ? newTime : 0)
  }

  const skipForward = () => {
    return currentTime(currentTime() + SKIP_INTERVAL)
  }

  const playbackFaster = () => {
    const newRate = playbackRate() * FORWARD_SPEED_INTERVAL
    if (newRate < MAX_PLAYBACK_RATE) {
      playbackRate(newRate)
    } else {
      playbackMax()
    }
  }

  const volume = (v) => {
    return playerRef.current.volume(v)
  }

  const volumeUp = () => {
    return volume(volume() + VOLUME_INTERVAL)
  }

  const volumeDown = () => {
    return volume(volume() - VOLUME_INTERVAL)
  }

  const toggleFullScreen = () => {
    if (playerRef.current.isFullscreen()) {
      playerRef.current.exitFullscreen()
    } else {
      playerRef.current.requestFullscreen()
    }
  }

  const keyDown = ev => {
    log(`${ev.code} is pressed`)
    if (!ev.code.startsWith('Meta') && !ev.code.startsWith('Alt')) {
      // Don't keep track of control keys.
      keys[ev.code] = ev
    }
    console.log(keys)
    const player = playerRef.current

    if (ev.repeat) {
      // Key is being held.
      if (keys[STOP_KEY]) {
        if (keys[FORWARD_KEY]) {
          playbackSlow()
        } else if (keys[REVERSE_KEY]) {
          playbackSlowReverse()
        }
      }
    } else if (ev.metaKey && ev.altKey && ev.code === REVERSE_KEY) {
      // Special handling is required since these keys open developer tools on Chrome.
      ev.preventDefault()
      delete keys[REVERSE_KEY]
    } else if (ev.metaKey || ev.ctrlKey) {
      switch (ev.code) {
        case ENTER_KEY:
          toggleFullScreen()
          break
        case EDIT_KEY:
          if (onEdit) {
            onEdit(ev)
          }
          break
        case MARK_KEY:
          // Special handling is required for Chrome since it opens the history tab.
          ev.preventDefault()
          if (onMark) {
            onMark(currentTime())
          }
          break
      }
      // Keyup is not sent when meta is also pressed.
      delete keys[ev.code]
    } else if (Object.keys(keys).length === 1) {
      switch (ev.code) {
        case CLEAR_KEY:
          if (onClear) {
            onClear(ev)
          }
          break
        case REVERSE_KEY:
          playbackReverse()
          break
        case FORWARD_KEY:
          if (player.paused()) {
            player.play()
          } else {
            playbackFaster()
          }
          break
        case STOP_KEY:
          if (player.paused()) {
            player.play()
          } else {
            if (isReverse.current) {
              isReverse.current = false
              playbackDefault()
            } else if (player.playbackRate() === 1) {
              player.pause()
            } else {
              playbackDefault()
            }
          }
          break
        case START_STOP_KEY:
          ev.preventDefault()
          if (player.paused()) {
            player.play()
          } else {
            if (isReverse.current) {
              isReverse.current = false
              playbackDefault()
            } else if (player.playbackRate() === 1) {
              player.pause()
            } else {
              playbackDefault()
            }
            isReverse.current = false
          }
          break
        case SKIP_BACK_KEY:
          ev.preventDefault()
          log(playerRef.current.currentTime())
          skipBack()
          break
        case SKIP_FORWARD_KEY:
          ev.preventDefault()
          skipForward()
          break
        case VOLUME_UP_KEY:
          ev.preventDefault()
          volumeUp()
          break
        case VOLUME_DOWN_KEY:
          ev.preventDefault()
          volumeDown()
          break
      }
    }
  }

  const keyUp = ev => {
    if (ev.code === FORWARD_KEY && playbackRate() === SLOW_PLAYBACK_RATE) {
      playbackDefault()
    } else if (ev.code === REVERSE_KEY && isSlowReverse.current) {
      isSlowReverse.current = false
      playbackDefault()
    }
    delete keys[ev.code]
  }

  useEffect(() => {
    if (!pauseEvents) {
      window.addEventListener('keydown', keyDown, false)
      window.addEventListener('keyup', keyUp, false)

      return () => {
        window.removeEventListener('keydown', keyDown, false)
        window.removeEventListener('keyup', keyUp, false)
      }
    }
  }, [pauseEvents])

  const visibilityChanged = () => {
    keys = {}
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityChanged)

    return () => {
      document.removeEventListener('visibilitychange', visibilityChanged)
    }
  })

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js')

      // TODO: Remove this? Why are we adding classes here?
      videoElement.classList.add('vjs-big-play-centered')
      videoRef.current.appendChild(videoElement)

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready')

        const qualityLevels = player.qualityLevels()
        qualityLevels.on('change', () => {
          if (onQualityChange) {
            const quality = qualityLevels[qualityLevels.selectedIndex]
            onQualityChange(quality)
          }
        })

        if (onReady) {
          onReady(player)
        }
      })

      if (onTimeChange) {
        player.on('timeupdate', () => {
          onTimeChange(player.currentTime())
        })
      }

      if (onRateChange) {
        player.on('ratechange', () => {
          onRateChange(player.playbackRate())
        })
      }

      player.on('fullscreenchange', () => {
        // Ensure the player has focus after a fullscreen event.
        player.focus()
      })

      player.on('loadedmetadata', () => {
        if (onLoadMetaData) {
          onLoadMetaData()
        }
      })

      player.on('canplaythrough', () => {
        if (onCanPlayThrough) {
          onCanPlayThrough()
        }
      })

      player.overlay({
        align: 'top',
        class: styles.noneOverlay,
        content: '',
        debug: false,
        overlays: [{
          content: 'Player is Paused',
          class: styles.defaultOverlay,
          start: 'pause',
          end: 'play'
        }]
      })
      console.log('Configured VideoJS overlay...')

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current

      // eslint-disable-next-line react/prop-types
      player.autoplay(options.autoplay)
      // eslint-disable-next-line react/prop-types
      // TODO: This check is not comprehensive. If removed, the video keeps refreshing on state changes.
      if (player.currentSource() && player.currentSource().src !== options.sources) {
        player.src(options.sources)
      }
    }
  }, [onRateChange, onReady, onTimeChange, options, videoRef])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player="">
      <div ref={videoRef}/>
    </div>
  )
}

export default VideoJS
