// import { Auth, Hub } from 'aws-amplify'
import * as React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Fab, Link, Tooltip } from '@mui/material'
import Feature from '../common/Feature'

const pages = []
const settings = []

const HighlightsAppBar = () => {
  const authenticator = useAuthenticator()

  return (
    <Box sx={{ flexGrow: 1, marginBottom: '.7rem' }}>
      <AppBar position="static">
        <Toolbar>
          <Link href="/">
            <IconButton
              size="medium"
              color="primary"
              edge="start"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <VideocamIcon/>
            </IconButton>
          </Link>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              flexGrow: 0,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            Highlights
          </Typography>
          <Button href="/uploads">
            Uploads
          </Button>
          <Button href="/tag">
            Tags
          </Button>
          <Feature flag={Feature.STATS}>
            <Button href="/stats">
              Stats
            </Button>
          </Feature>
          {/* Fill the extra space on the AppBar */}
          <Box sx={{ flexGrow: 1 }}>&nbsp;</Box>

          <Tooltip title="Add a new upload.">
            <Link sx={{ marginRight: '1em' }} href="/new" aria-label="Add a new Upload">
              <Fab color="success" size="small" aria-label="add">
                <VideoCallIcon/>
              </Fab>
            </Link>
          </Tooltip>
          {
            authenticator.user
              ? <Button color="primary" onClick={authenticator.signOut}>Sign Out ({authenticator.user.username})</Button>
              : <>
                <Button color="primary" onClick={authenticator.toSignIn}>Sign In</Button> /
                <Button color="primary" onClick={authenticator.toSignUp}>Sign Up</Button>
              </>
          }
        </Toolbar>
      </AppBar>
    </Box>
  )
}

HighlightsAppBar.propTypes = {
  user: PropTypes.object,
  signOut: PropTypes.func
}

export default HighlightsAppBar
