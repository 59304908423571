import * as React from 'react'
import { Popover, Typography } from '@mui/material'
import styles from '../../css/Card.module.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PropTypes from 'prop-types'

LinkPopover.propTypes = {
  children: PropTypes.any,
  el: PropTypes.object,
  setEl: PropTypes.func,
  vertical: PropTypes.string,
  horizontal: PropTypes.string
}

export default function LinkPopover (props) {
  const {
    el,
    setEl,
    vertical = 'top',
    horizontal = 'right'
  } = props

  return (
    <>
      <Popover
        id="linkPopover"
        open={Boolean(el)}
        anchorEl={el}
        onClose={() => setEl(null)}
        anchorOrigin={{
          vertical,
          horizontal
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Typography
          sx={{ p: 2 }}
          className={styles.cardTitle}
          color="success.main">
          <CheckCircleIcon
            fontSize="small"
            sx={{ verticalAlign: 'middle' }}
          /> Link copied to clipboard.
        </Typography>
      </Popover>
    </>
  )
}
