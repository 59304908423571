import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from '../../css/Card.module.css'
import { Card, CardContent, Tooltip, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { DateTime } from 'luxon'
import Delimeter from '../common/Delimeter'
import { Auth } from 'aws-amplify'

UploadCard.propTypes = {
  upload: PropTypes.object,
  onEdit: PropTypes.func
}

export default function UploadCard (props) {
  const [canEdit, setCanEdit] = useState(false)
  const [canDelete, setCanDelete] = useState(false)
  const {
    upload,
    onEdit
  } = props

  useEffect(() => {
    const getUserGroups = async () => {
      const user = await Auth.currentAuthenticatedUser()
      const isOwner = user.username === upload.owner
      const groups = user.signInUserSession.accessToken.payload['cognito:groups']

      if (isOwner) {
        setCanEdit(true)
        setCanDelete(true)
      } else {
        for (let i = 0; i < groups.length; i++) {
          if (upload.groups.find(group => group === groups[i])) {
            setCanEdit(true)
            return
          }
        }
      }
    }
    getUserGroups()
  }, [])

  const editClicked = ev => {
    if (onEdit) {
      onEdit(ev, upload)
    }
  }

  const deleteClicked = () => {

  }

  return (
    <>
      <Card className={styles.card}>
        <CardContent>
          <Typography noWrap className={styles.cardTitle} gutterBottom>
            {upload.name}
          </Typography>
          <Typography className={styles.cardTitle} gutterBottom>
            <Tooltip title="Edit the upload.">
              <span>
                <IconButton color="primary" size="small" onClick={editClicked} disabled={!canEdit}>
                  <EditIcon fontSize="small"/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete the current highlight.">
            <span>
              <IconButton color="secondary" size="small" onClick={deleteClicked} disabled={!canDelete}>
                <DeleteIcon fontSize="small"/>
              </IconButton>
            </span>
            </Tooltip>
          </Typography>
          {
            upload.description
              ? <Typography variant="body2" color="text.secondary">
                {
                  upload.description.split('\n').map((token, index) => {
                    return (
                      <span key={index}>
                      {token}<br/>
                    </span>
                    )
                  })
                }
              </Typography>
              : null
          }
          {
            upload.owner
              ? <Typography variant="body2" color="text.secondary">
                Owner: {upload.owner}
              </Typography>
              : null
          }
          {
            upload.groups
              ? <Typography variant="body2" color="text.secondary">
                Groups: <Delimeter values={upload.groups}/>
              </Typography>
              : null
          }
          {
            upload.date
              ? <Typography variant="body2" color="text.secondary">
                Date: {upload.date ? DateTime.fromISO(upload.date).toLocaleString(DateTime.DATETIME_MED) : 'NA'}
              </Typography>
              : null
          }
        </CardContent>
      </Card>
    </>
  )
}
