/* eslint-disable */

export const highlightsByMonth = /* GraphQL */ `
    query HighlightsByMonth(
        $month: String!, 
        $sortDirection: ModelSortDirection, 
        $nextToken: String, 
        $limit: Int, 
        $filter: ModelHighlightFilterInput) {
        highlightsByMonth(
            month: $month, 
            sortDirection: $sortDirection, 
            nextToken: $nextToken, 
            limit: $limit, 
            filter: $filter) {
            items {
                createdAt
                description
                end
                groups
                id
                month
                name
                owner
                start
                tags {
                    items {
                        createdAt
                        date
                        groups
                        highlightTagsId
                        id
                        month
                        name
                        owner
                        updatedAt
                    }
                }
                upload {
                    createdAt
                    date
                    description
                    groups
                    id
                    name
                    owner
                    updatedAt
                    url
                    source
                }
                uploadHighlightsId
            }
            nextToken
        }
    }
`

export const tagsByMonth = /* GraphQL */ `
    query TagsByMonth(
        $month: String!
        $dateName: ModelTagTagsByMonthCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelTagFilterInput
        $limit: Int
        $nextToken: String
    ) {
        tagsByMonth(
            month: $month
            dateName: $dateName
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                month
                date
                highlight {
                    id
                    name
                    description
                    start
                    end
                    owner
                    groups
                    createdAt
                    updatedAt
                    uploadHighlightsId
                    upload {
                        id
                        name
                        source
                        url
                    }
                    tags {
                        items {
                            id
                            name
                        }
                    }
                }
                owner
                groups
                createdAt
                updatedAt
                highlightTagsId
            }
            nextToken
        }
    }
`

export const tagsByOwner = /* GraphQL */ `
    query TagsByOwner(
        $owner: String!
        $dateName: ModelTagTagsByOwnerCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelTagFilterInput
        $limit: Int
        $nextToken: String
    ) {
        tagsByOwner(
            owner: $owner
            dateName: $dateName
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                name
                month
                date
                highlight {
                    id
                    name
                    description
                    start
                    end
                    owner
                    groups
                    createdAt
                    updatedAt
                    uploadHighlightsId
                }
                owner
                groups
                createdAt
                updatedAt
                highlightTagsId
            }
            nextToken
        }
    }
`