import React, { useState } from 'react'
import styles from '../../css/Highlight.module.css'
import PropTypes from 'prop-types'
import { Grid, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import GroupOptions from '../common/GroupOptions'
import TagsEdit from '../common/TagsEdit'
import Feature from '../common/Feature'

HighlightForm.propTypes = {
  title: PropTypes.string,
  value: PropTypes.object,
  tags: PropTypes.array,
  stats: PropTypes.array,
  groups: PropTypes.array,
  onNameChange: PropTypes.func,
  onDescriptionChange: PropTypes.func,
  onStartChange: PropTypes.func,
  onEndChange: PropTypes.func,
  onGroupChange: PropTypes.func,
  onTagsChange: PropTypes.func,
  onStatsChange: PropTypes.func
}

export default function HighlightForm (props) {
  const {
    title,
    value,
    tags,
    stats,
    groups,
    onNameChange,
    onDescriptionChange,
    onStartChange,
    onEndChange,
    onGroupChange,
    onTagsChange,
    onStatsChange
  } = props

  const [tagOptions, setTagOptions] = useState([])

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    autoFocus
                    className={styles.input}
                    variant="outlined"
                    value={value.name || ''}
                    onChange={ev => onNameChange ? onNameChange(ev) : null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    label="Description"
                    multiline
                    rows={4}
                    className={styles.input}
                    value={value.description}
                    onChange={ev => onDescriptionChange ? onDescriptionChange(ev) : null}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="start"
                    label="Start"
                    className={styles.input}
                    variant="outlined"
                    value={value.start}
                    onChange={ev => onStartChange ? onStartChange(ev) : null}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="end"
                    label="End"
                    className={styles.input}
                    variant="outlined"
                    value={value.end}
                    onChange={ev => onEndChange ? onEndChange(ev) : null}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TagsEdit
                    tags={tags}
                    options={tagOptions}
                    onChange={onTagsChange}
                    placeholder="Enter tags for this highlight"
                  />
                </Grid>
                <Feature flag={Feature.STATS}>
                  <Grid item xs={12}>
                    <TagsEdit
                      tags={stats}
                      options={tagOptions}
                      onChange={onStatsChange}
                      label="Stats"
                      placeholder="Enter stats for this highlight"
                    />
                  </Grid>
                </Feature>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              {
                groups
                  ? <>
                    <GroupOptions
                      groups={groups}
                      selectedGroups={value.groups}
                      onGroupChange={onGroupChange}
                    />
                  </>
                  : null
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
