import React from 'react'
import styles from '../../css/Card.module.css'
import { Card, CardContent, Grid, Paper } from '@mui/material'
import Typography from '@mui/material/Typography'

export default function ControlsCard () {
  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography className={styles.cardTitle} gutterBottom>
          Playback Controls
        </Typography>
        <Typography component="span" variant="body2" color="text.secondary">
          <Grid container rowSpacing={0.5} spacing={1}>
            <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>&lt;space&gt;</Paper>
            </Grid>
             <Grid item xs={10}>Play or pause</Grid>

             <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>&lt;left arrow&gt;</Paper>
             </Grid>
             <Grid item xs={10}>Skip backward 5s</Grid>

             <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>&lt;right arrow&gt;</Paper>
             </Grid>
             <Grid item xs={10}>Skip forward 5s</Grid>

             <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>&lt;up arrow&gt;</Paper>
             </Grid>
             <Grid item xs={10}>volume up</Grid>

             <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>&lt;down arrow&gt;</Paper>
             </Grid>
             <Grid item xs={10}>volume down</Grid>

             <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>&lt;right arrow&gt;</Paper>
             </Grid>
             <Grid item xs={10}>Skip forward 5s</Grid>

             <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>&lt;j&gt;</Paper>
             </Grid>
             <Grid item xs={10}>1.5x reverse</Grid>

             <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>&lt;l&gt;</Paper>
             </Grid>
             <Grid item xs={10}>1.5x forward</Grid>

             <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>hold &lt;j&gt; + &lt;k&gt;</Paper>
             </Grid>
             <Grid item xs={10}>Slow motion back</Grid>

             <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>&lt;k&gt;</Paper>
             </Grid>
             <Grid item xs={10}>Play or pause</Grid>

             <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>hold &lt;k&gt; + &lt;l&gt;</Paper>
             </Grid>
             <Grid item xs={10}>Slow motion forward</Grid>

             <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>&lt;M&gt; + &lt;y&gt;</Paper>
             </Grid>
             <Grid item xs={10}>Set or complete a mark</Grid>

            <Grid item xs={2}>
              <Paper className={styles.key} elevation={15}>&lt;M&gt; + &lt;e&gt;</Paper>
            </Grid>
            <Grid item xs={10}>Edit the current highlight</Grid>
          </Grid>
        </Typography>
      </CardContent>
    </Card>
  )
}
