/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUpload = /* GraphQL */ `
  query GetUpload($id: ID!) {
    getUpload(id: $id) {
      id
      name
      description
      date
      url
      source
      highlights {
        items {
          id
          name  
          start
        }
        nextToken
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUploads = /* GraphQL */ `
  query ListUploads(
    $filter: ModelUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        date
        url
        source
        owner
        groups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const uploadsBySource = /* GraphQL */ `
  query UploadsBySource(
    $source: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    uploadsBySource(
      source: $source
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        date
        url
        source
        owner
        groups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHighlight = /* GraphQL */ `
  query GetHighlight($id: ID!) {
    getHighlight(id: $id) {
      id
      month
      name
      description
      start
      end
      upload {
        id
        name
        description
        date
        url
        source
        owner
        groups
        createdAt
        updatedAt
        __typename
      }
      tags {
        items {
          id   
          name
          owner
          groups
          date
          month
          highlightTagsId
        }
        nextToken
        __typename
      }
      stats {
        items {
          id
          name
          owner
          groups
          date
          month
          highlightStatsId
        }
        nextToken
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      uploadHighlightsId
      __typename
    }
  }
`;
export const listHighlights = /* GraphQL */ `
  query ListHighlights(
    $filter: ModelHighlightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHighlights(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        month
        name
        description
        start
        end
        owner
        groups
        createdAt
        updatedAt
        uploadHighlightsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const highlightsByMonth = /* GraphQL */ `
  query HighlightsByMonth(
    $month: String!
    $sortDirection: ModelSortDirection
    $filter: ModelHighlightFilterInput
    $limit: Int
    $nextToken: String
  ) {
    highlightsByMonth(
      month: $month
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        month
        name
        description
        start
        end
        owner
        groups
        createdAt
        updatedAt
        uploadHighlightsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      month
      date
      highlight {
        id
        month
        name
        description
        start
        end
        owner
        groups
        createdAt
        updatedAt
        uploadHighlightsId
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      highlightTagsId
      __typename
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        month
        date
        owner
        groups
        createdAt
        updatedAt
        highlightTagsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tagsByMonth = /* GraphQL */ `
  query TagsByMonth(
    $month: String!
    $dateName: ModelTagTagsByMonthCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByMonth(
      month: $month
      dateName: $dateName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        month
        date
        owner
        groups
        createdAt
        updatedAt
        highlightTagsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tagsByOwner = /* GraphQL */ `
  query TagsByOwner(
    $owner: String!
    $dateName: ModelTagTagsByOwnerCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByOwner(
      owner: $owner
      dateName: $dateName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        month
        date
        owner
        groups
        createdAt
        updatedAt
        highlightTagsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStats = /* GraphQL */ `
  query GetStats($id: ID!) {
    getStats(id: $id) {
      id
      name
      month
      date
      highlight {
        id
        month
        name
        description
        start
        end
        owner
        groups
        createdAt
        updatedAt
        uploadHighlightsId
        __typename
      }
      owner
      groups
      createdAt
      updatedAt
      highlightStatsId
      __typename
    }
  }
`;
export const listStats = /* GraphQL */ `
  query ListStats(
    $filter: ModelStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        month
        date
        owner
        groups
        createdAt
        updatedAt
        highlightStatsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const statsByMonth = /* GraphQL */ `
  query StatsByMonth(
    $month: String!
    $dateName: ModelStatsStatsByMonthCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statsByMonth(
      month: $month
      dateName: $dateName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        month
        date
        owner
        groups
        createdAt
        updatedAt
        highlightStatsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
