import React from 'react'
import './App.css'
import PropTypes from 'prop-types'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Upload from './pages/Upload'
import UploadFile from './pages/UploadFile'
import Uploads from './pages/Uploads'
import Tag from './pages/Tag'
import HighlightsAppBar from './components/menu/HighlightsAppBar'
import Highlight from './pages/Highlight'
import Stats from './pages/Stats'

App.propTypes = {
  user: PropTypes.object,
  toSignIn: PropTypes.func,
  signOut: PropTypes.func
}

export default function App () {
  return (
    <BrowserRouter>
      <HighlightsAppBar/>
      <Routes>
        <Route path="/" element={<Highlight/>}/>
        <Route path="/highlight" element={<Highlight/>}/>
        <Route path="/new" element={<UploadFile/>}/>
        <Route path="/uploads" element={<Uploads/>}/>
        <Route path="/upload" element={<Upload/>}/>
        <Route path="/upload/:uploadId" element={<Upload/>}/>
        <Route path="/upload/:uploadId/highlight/:highlightId" element={<Upload/>}/>
        <Route path="/tag" element={<Tag/>}/>
        <Route path="/tag/:tag" element={<Tag/>}/>
        <Route path="/stats" element={<Stats/>}/>
      </Routes>
    </BrowserRouter>
  )
}
