const SPLITTER = '-'

class Player {
  constructor (name) {
    this.name = name

    // Player
    this.evenStrengthGoals = 0
    this.evenStrengthAssists = 0
    this.powerPlayGoals = 0
    this.powerPlayAssists = 0
    this.penalties = 0
    this.plusMinus = 0
    this.faceoffWins = 0
    this.faceoffLosses = 0

    // Goalie
    this.goalsAgainst = 0
  }

  get goals () {
    return this.evenStrengthGoals + this.powerPlayGoals
  }

  get assists () {
    return this.evenStrengthAssists + this.powerPlayAssists
  }

  get points () {
    return this.goals + this.assists
  }

  get evenStrengthPoints () {
    return this.evenStrengthGoals + this.evenStrengthAssists
  }

  get powerPlayPoints () {
    return this.powerPlayGoals + this.powerPlayAssists
  }

  get faceoffs () {
    return this.faceoffWins + this.faceoffLosses
  }

  get faceoffPercentage () {
    return this.faceoffWins / this.faceoffs
  }

  get pims () {
    return this.penalties * 1.5
  }
}

class Stats {
  constructor (stats) {
    this.players = new Map()
    this.addStats(stats)
  }

  addStat (s) {
    if (!s) {
      return
    }
    // console.log(`Processing stat: ${s.name ? s.name : s}`)
    let statsString
    if (typeof s === 'string') {
      statsString = s
    } else {
      statsString = s.name
    }

    // Debug
    // console.log(statsString)

    if (typeof statsString !== 'string') {
      throw new Error(`Invalid stats string type: ${typeof statsString}`)
    }
    const [name, stat] = statsString.split(SPLITTER)
    if (!name || !stat) {
      throw new Error(`Unable to parse stats string: ${statsString}`)
    }
    let player = this.players.get(name)
    if (!player) {
      // Create new player.
      player = new Player(name)
      this.players.set(name, player)
    }
    switch (stat) {
      case 'ESG':
        player.evenStrengthGoals += 1
        break
      case 'ESA':
        player.evenStrengthAssists += 1
        break
      case 'PPG':
        player.powerPlayGoals += 1
        break
      case 'PPA':
        player.powerPlayAssists += 1
        break
      case 'PLU':
        player.plusMinus += 1
        break
      case 'MIN':
        player.plusMinus -= 1
        break
      case 'PEN':
        player.penalties += 1
        break
      case 'GAG':
        player.goalsAgainst += 1
        break
      case 'FOW':
        player.faceoffWins += 1
        break
      case 'FOL':
        player.faceoffLosses += 1
        break
      default:
        throw new Error(`Invalid stat: ${stat} for name: ${name}`)
    }
  }

  addStats (a) {
    if (!a) {
      return
    }
    for (const s of a) {
      this.addStat(s)
    }
  }

  toString () {
    return JSON.stringify(this.players, null, 2)
  }
}

export default stats => {
  return new Stats(stats)
}
