import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'

ViewButton.propTypes = {
  url: PropTypes.string
}

export default function ViewButton (props) {
  const { url } = props

  return (
    <>
      <Button href={url} size="small" color="primary">
        <PlayCircleOutlineIcon fontSize="small"/>&nbsp;View
      </Button>
    </>
  )
}
